export const updateUserName = name => ({
  type: 'UPDATE_USER_NAME',
  payload: name,
});

export const fetchUserPreferences = preferences => ({
  type: 'FETCH_USER_PREFERENCES',
  payload: preferences,
});

export const resetUser = () => ({
  type: 'RESET_USER',
});
