import '../css/studentRequest.css';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import '../css/style.css';
import dashImg1 from '../assets/dash-img-01.png';
import dashImg2 from '../assets/dash-img-02.png';
import dashImg3 from '../assets/dash-img-03.png';
import dashImg4 from '../assets/dash-img-04.png';
import TablePagination from '@mui/material/TablePagination';
import Cards from './Cards';
import Avatar from '@material-ui/core/Avatar';
import school from '../assets/icon1.png';
import books from '../assets/icon2.png';
import glasses from '../assets/icon3.png';
import student from '../assets/user.png';
import details from '../assets/details.png';
import ActiveReq from '../assets/activeReq.png'
import FullfilledReq from '../assets/fullfilledReq.png'
import PledgedReq from '../assets/pledgedReq.png'
import SupportedReq from '../assets/SupportedReq.png'
import Table from './Table';
import { formatDate } from '../utils/dateUtils';
import StudentRequestTable from '../components/StudentStatusTable';

// Styling with Material-UI
const useStyles = makeStyles(theme => ({
  // your styles here
}));

// Function to get card data based on role
const getCardData = (role, data) => {
  const {
    balance,
    RequestSponsored,
    RequestReceived,
    RequestFullfill,
    RequestPledged,
    RequestActiveStudent,
    TotalReqMade,
    VolunteerHours,
    ProjectHours,
    CategoryBalances,
    totalDonatedAmount,
  } = data;

  if (role === 'sponsor') {
    return [
      { img: dashImg1, number: RequestReceived, title: 'Requests Received' },
      { img: dashImg2, number: RequestSponsored, title: 'Requests Sponsored' },
      {
        img: dashImg3,
        number: `$ ${totalDonatedAmount}`,
        title: 'Total Donation',
      },
      { img: dashImg4, number: `$ ${balance}`, title: 'Total Balance' },
      {
        img: books,
        number: `$ ${CategoryBalances.Books || 0}`,
        title: 'Books Balance',
      },
      {
        img: school,
        number: `$ ${CategoryBalances.Tuition || 0}`,
        title: 'Tuition Balance',
      },
      {
        img: glasses,
        number: `$ ${CategoryBalances['Pair Of Glasses'] || 0}`,
        title: 'Glasses Balance',
      },
      { img: details, number: 'Other Details', title: 'Explore More' },
    ];
  } else if (role === 'student') {
    return [
      {
        img: ActiveReq,
        number: RequestActiveStudent,
        title: 'Active Requests',
      },
      {
        img: FullfilledReq,
        number: RequestFullfill,
        title: 'Requests fullfilled',
      },
      {
        img: PledgedReq,
        number: RequestPledged,
        title: 'Request pledged',
      },
      {
        img: SupportedReq,
        number: RequestFullfill,
        title: 'Supported requests',
      },
    ];
  } else {
    return [
      {
        img: dashImg1,
        number: TotalReqMade,
        title: 'Requests made',
      },
      {
        img: dashImg1,
        number: RequestFullfill,
        title: 'Requests fullfilled',
      },
      {
        img: dashImg1,
        number: VolunteerHours,
        title: 'Hours pledged',
      },
      {
        img: dashImg1,
        number: ProjectHours,
        title: 'Hours volunteered',
      },
      {
        img: dashImg1,
        number: VolunteerHours,
        title: 'Sponsorship Request pledged',
      },
      {
        img: dashImg1,
        number: ProjectHours,
        title: 'Sponsorship Supported requests',
      },
    ];
  }
};

export default function CommonDashboard(props) {
  const classes = useStyles();
console.log("my props ", props);

  const {
    balance,
    donationData,
    RequestSponsored,
    RequestFullfill,
    RequestPledged,
    RequestActiveStudent,
    TotalReqMade,
    VolunteerHours,
    ProjectHours,
    RequestReceived,
    CategoryBalances,
    loading,
    role,
  } = props;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(3);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page on page size change
  };

  // Calculate the sum of donated amounts
  const totalDonatedAmount = donationData.reduce((acc, donation) => {
    return acc + (donation.donateamount || 0); // Default to 0 if donateamount is undefined
  }, 0);

  // Calculate amount for specific need_type

  const rows = donationData;

  // Prepare data for card rendering
  const data = {
    balance,
    donationData,
    RequestSponsored,
    RequestReceived,
    RequestFullfill,
    RequestPledged,
    RequestActiveStudent,
    TotalReqMade,
    VolunteerHours,
    ProjectHours,
    CategoryBalances,
    totalDonatedAmount,
  };
  const Carddata = getCardData(role, data);

  const columns = [
    'User Image',
    'Name',
    'Application No.',
    'Donated Amount',
    'Need Type',
    'Request Raised On',
    'Need Date',
    'Fulfilled On',
  ];

  //Render rows
  const renderRow = rowData => (
    <>
      <td>
        <Avatar alt={rowData.name} src={student || ''} />
      </td>
      <td>{rowData.name}</td>
      <td>{rowData.applicationnumber}</td>
      <td>{rowData.donateamount}</td>
      <td>
        <img
          src={getNeedTypeClass(rowData.needtype)}
          width="40%"
          alt={rowData.needtype}
        />
        <p style={{ fontSize: '13px' }}>{rowData.needtype}</p>
      </td>
      <td>{formatDate(rowData.requestdate)}</td>
      <td>{formatDate(rowData.needdateline)}</td>
      <td>{formatDate(rowData.fulfilledon)}</td>
    </>
  );

  // Function to get the appropriate class based on needtype
  const getNeedTypeClass = needtype => {
    switch (needtype) {
      case 'Books':
        return books;
      case 'Tuition':
        return school;
      case 'Pair Of Glasses':
        return glasses;
      default:
        return '';
    }
  };

  return (
    <>
      <section className="dashboard_main_section banner-image">
        <div className="submit-section">
          <div className="container">
            <div className="row">
              {Carddata.map((item, index) => (
                <Cards
                  key={`${item.title}-${index}`} // Unique key combining title and index
                  img={item.img}
                  title={item.title}
                  number={item.number}
                />
              ))}
            </div>
          </div>
          {role==='student'? <StudentRequestTable/> : role==='sponsor'?
          <section className="sponsorship-requests-section">
            <div className="container">
            <div className='mb-2'>
            <b className="fs-4 ">Donation Data</b>
            </div>
              
              <div className="shadow-box">
                <div className="table-bottom table-sec">
                  <Table
                    columns={columns}
                    rows={rows}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    renderRow={renderRow}
                    loading={loading}
                  />
                </div>
              </div>
            </div>
          </section>
          : ''}
        </div>
      </section>
    </>
  );
}
