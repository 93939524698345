import React from 'react';
import TablePagination from '@mui/material/TablePagination';
import Spinner from '../utils/spinner';

function Table({
  columns,
  rows,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  renderRow,
  loading
}) {
  return (
    <>
    {loading ? <Spinner/> : (
    <div className="table-box">
      <div className="scroll-table-box">
        <table width="100%" cellPadding={0} cellSpacing={0}>
          <thead>
            <tr>
              {columns.map((column, index) => (
                <th key={index}>{column}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.length > 0 ? (
              rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((rowData, index) => (
                <tr key={rowData.id || index}>
                  {renderRow(rowData, index)}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={columns.length} className="no-record">
                  No records to display
                </td>
              </tr>
            )}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={columns.length} style={{borderTop:'1px solid'}}>
                <TablePagination
                  rowsPerPageOptions={[3, 5, 10]}
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                />
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
    )}
    </>
  );
}

export default Table;
