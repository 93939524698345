// Functionality of this code : This page contains the transition functionality of the images on the Main Card on the Home Page
// Input : None
// Output : Home Page
// Written by: Initial team VK, KR

import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
// import homebanner from '../assets/homebanner.png';
// import homebanner from '../assets/banner-img.jpg';

import homebanner from '../assets/poster-new1.png';
import '../css/style.css';

function useInterval(callback, delay) {
  const savedCallback = useRef();
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

// home page has a main jumbo poster
export default function MainFeaturedPost(props) {
  return (
    <>
      <section id="home" className="w3l-banner py-5 lg:mt-0">
        <div className="banner-image"></div>
        <div className="banner-content">
          <div className="container pt-5 pb-md-4">
            <div className="row align-items-center py-4">
              <div className="col-md-6 pr-lg-5">
                <h3 className="mb-lg-4 mb-3 title">
                  Help{' '}
                  <span>
                    deserving <br />
                    students
                  </span>{' '}
                  get to the <br /> finish line!!{' '}
                </h3>
                <p className="mr-5">
                  The platform brings together students and sponsors in a <br />
                  <b>Direct-to-Invoice</b> sponsorship model. <b>Students</b>{' '}
                  can raise a request and pledge to support others in future or
                  offer to volunteer with their skills ! The platform aims at
                  creating transparency to the sponsors, fostering a
                  pay-it-forward model for the students and therefore <br />
                  <b>promotes a virtuous cycle!</b>
                </p>
                <div className="mt-md-5 mt-4 mb-lg-0 mb-4">
                  <div
                    className="btn btn-style"
                    style={{ background: '#77000D' }}
                    onClick={() => {
                      props.setPage('auth');
                    }}
                  >
                    Get Started
                  </div>
                </div>
              </div>
              <div className="col-md-6 mt-md-0 mt-5 mb-lg-0 mb-5 right-banner-2 position-relative">
                <div className="myhomeimage">
                  <img
                    src={homebanner}
                    className="img-fluid radius-image-full position-relative"
                    alt=" "
                  />
                </div>
                <div className="banner-style-1 banner-style-2 d-flex align-items-center">
                  <i className="fa-solid fa-paper-plane primary-clr-bg"></i>
                  <h4>Promoting a virtuous cycle</h4>
                </div>
                <div className="banner-style-1 banner-style-3 d-flex align-items-center">
                  <i className="fa-solid fa-users-line green-clr-bg"></i>
                  <h4>Supporting UNSDG 4</h4>
                </div>
                <div className="banner-style-1 banner-style-4 d-flex align-items-center">
                  <i className="fa-solid fa-file-invoice-dollar blue-clr-bg"></i>
                  <h4>Direct-to-Invoice model</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

MainFeaturedPost.propTypes = {
  post: PropTypes.object,
};
