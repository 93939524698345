export const formatDate = dateString => {
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    weekday: 'short',
  };
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', options);
};
