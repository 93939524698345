// Functionality of this code : This code has the Table of Student's requests details assigned to Sponsor
// Input : None
// Output : Sponsor Requests table
// Written by: CIS

import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import * as firebase from 'firebase';
import Container from '@material-ui/core/Container';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import PaymentIcon from '@material-ui/icons/Payment';
import certification from '../assets/certification.jpg';
import airmiles from '../assets/airmiles.jpg';
import mealPlan from '../assets/mealPlan.jpg';
import insurance from '../assets/insurance.jpg';
import school from '../assets/icon1.png';
import laptop from '../assets/laptop.jpg';
import housing from '../assets/housing.jpg';
import books from '../assets/icon2.png';
import others from '../assets/others.jpg';
import glasses from '../assets/icon3.png';
import mrt from '../assets/mrt.jpg';
import { Checkbox } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Slider from '@material-ui/core/Slider';
import { default as ReactSelect } from 'react-select';
// import { toast } from 'react-toastify';
import toast from 'react-hot-toast';
import 'react-toastify/dist/ReactToastify.css';
import { components } from 'react-select';
import axios from 'axios';
import download from 'downloadjs';
import tableIcons from '../admin/MaterialTableIcons';
import UniquegGift from './UniqueGift';
import Iframe from 'react-iframe';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { TableFooter, TablePagination, TableRow } from '@material-ui/core';
import Spinner from '../utils/spinner';
import Table from '../common/Table';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: '50px',
    height: '70px',
    marginLeft: '30px',
  },
  widthBox: {
    // maxWidth: '450px',
    // marginLeft: '35%',
  },
}));
const DonationTimesSlider = props => {
  const [value, setValue] = React.useState(1);
  const handleSliderChange = (event, newValue) => {
    props.setAmount(newValue);
  };
  const useStyles2 = makeStyles({
    root: {
      width: 250,
      marginTop: 20,
    },
    input: {
      width: 42,
    },
  });
  const classes = useStyles2();
  return (
    <div className={classes.root}>
      {/* <Typography color="primary" id="input-slider" gutterBottom>
        Amount willing to sponsor
      </Typography> */}
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <PaymentIcon />
        </Grid>
        <Grid item xs>
          <Slider
            min={0}
            step={1}
            max={props.requestAmt}
            valueLabelDisplay="auto"
            value={props.leftOver}
            onChange={handleSliderChange}
            aria-labelledby="input-slider"
          />
        </Grid>
        <Grid item>
          <Typography id="input-slider" gutterBottom>
            ${props.requestAmt}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

function GiveBackDialog(props) {
  // console.log('My Props ==== > ', props);

  var [payLater, setPayLater] = React.useState(false);
  var [purchase, setPurchase] = React.useState([]);
  const [shown, setShown] = React.useState(false);
  const [selected, setVoucherSelect] = React.useState([]);
  const [gifts, setGift] = React.useState([]);
  var [amount, setAmount] = React.useState(0);
  var [oldAmount, setOldAmount] = React.useState(0);
  const [voucher_need, setVoucherNeed] = React.useState('');
  const [amt2, setVoucherAmt] = React.useState(0);
  const [file, setFile] = React.useState();
  const [gOptions, setG] = React.useState([]);
  const classes = useStyles();
  let temp = [];

  const dataF = async () => {
    await firebase
      .auth()
      .currentUser.getIdTokenResult()
      .then(async function (tokenResult) {
        const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            auth: tokenResult.token,
          },
          body: JSON.stringify({ tokenResult }),
        };
        let val;
        // let temp = [];
        fetch(process.env.REACT_APP_PROXY + '/user/cartItem', requestOptions)
          .then(res => res.json())
          .then(async data => {
            data.data.map(async e => {
              val = e;
              gifts.push(val);
              await gifts.map(e => {
                let obj = {};
                obj.value = e.id;
                obj.amount = e.amount;
                obj.sms_content = e.sms_content;
                obj.category = e.category;
                obj.food_option = e.food_option;
                obj.online_voucher_id = e.online_voucher_id;
                obj.label = e.food_option
                  ? e.category +
                    ' ( ' +
                    e.food_option +
                    ' ) ' +
                    ' ( S$' +
                    e.amount +
                    ' ) '
                  : e.category + ' ( S$' + e.amount + ' ) ';
                // obj.label = e.product_name + ' (' + e.category + ')';
                obj.id = e.id;
                obj.product = e.product_id;
                obj.expiry = e.expiry_date;
                obj.url = e.url;

                temp.push(obj);
                // temp
                setPurchase([...temp]);
                var uniq = {};
                var arr = temp;
                let obj2 = {};
                obj2.label = 'Purchase more voucher';

                var arrFiltered = arr.filter(
                  obj => !uniq[obj.id] && (uniq[obj.id] = true)
                );

                arrFiltered.push(obj2);
                setPurchase([...arrFiltered]);
              });
            });

            // setGift(gifts)
          })
          .catch(error => {
            console.log(error);
          });
      })
      .catch(err => {
        console.log(err);
      });
  };

  var needFilter = purchase.filter(obj => {
    return (
      obj.category.toLowerCase() === props.need_type.toLowerCase() ||
      obj.category === 'Any' ||
      obj.label === 'Purchase more voucher'
    );
  });

  useEffect(() => {
    setOldAmount(props.fAmt);
    setVoucherNeed(props.need_type);
    dataF();
    if (props.open == true) {
      setG([...needFilter]);
    }
  }, [props.fAmt]);

  const handleClose = () => {
    setFile('');
    props.setOpen(false);
    setVoucherAmt(0);
  };

  const handleChangeDonate = () => {
    props.setIsNew(true);
    props.setOpen(true);
    dataF();
  };

  const handleConfirmBox = () => {
    props.setConfirm(true);
  };

  const handleYes = () => {
    const formData = new FormData();
    firebase
      .auth()
      .currentUser.getIdToken()
      .then(async function (idToken) {
        const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            auth: idToken,
          },
          body: JSON.stringify({
            amount:
              amt2 > amount ? amt2 + oldAmount : Number(amount) + oldAmount,
            selected,
            later: true,
            need_type: props.need_type,
            student_id: props.student_id,
            reqAmt: props.requestAmt,
            application_no: props.application_no,
          }),
        };
        if (props.need_type === 'Tuition') {
          const requestOptionsFile = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              auth: idToken,
            },
          };
          formData.append('payment', file);
          try {
            await axios
              .post(
                process.env.REACT_APP_PROXY + '/user/paymentSlip',
                formData,
                requestOptionsFile,
                {}
              )
              .then(async res => {
                toast.success('File Uploaded Successfully');
              });
          } catch (ex) {
            toast.error(ex, { autoClose: 2000 });
          }
        }
        fetch(process.env.REACT_APP_PROXY + '/user/donate', requestOptions)
          .then(res => res.json())
          .then(data => {
            if (data.success) {
              props.setOpen(false);
              props.setLoading(true);
              // setAmount(1);
              toast.success(data.message);
              // setVoucher(1);
            } else {
              alert('missing or incorrect fields');
            }
          })
          .catch(function (error) {
            toast.error(error);
            console.log(error);
          });
        setVoucherAmt(0);
        props.setConfirm(false);
      })
      .catch(e => {
        console.log(e);
      });
  };

  const handleNo = () => {
    const formData = new FormData();
    firebase
      .auth()
      .currentUser.getIdToken()
      .then(async function (idToken) {
        const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            auth: idToken,
          },
          body: JSON.stringify({
            amount:
              amt2 > amount ? amt2 + oldAmount : Number(amount) + oldAmount,
            selected,
            later: false,
            need_type: props.need_type,
            student_id: props.student_id,
            reqAmt: props.requestAmt,
            application_no: props.application_no,
          }),
        };
        if (props.need_type === 'Tuition') {
          const requestOptionsFile = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              auth: idToken,
            },
          };
          formData.append('payment', file);
          try {
            await axios
              .post(
                process.env.REACT_APP_PROXY + '/user/paymentSlip',
                formData,
                requestOptionsFile,
                {}
              )
              .then(async res => {
                toast.success('File Uploaded Successfully', {
                  autoClose: 2000,
                });
              });
          } catch (ex) {
            toast.error(ex);
          }
        }
        fetch(process.env.REACT_APP_PROXY + '/user/donate', requestOptions)
          .then(res => res.json())
          .then(data => {
            if (data.success) {
              props.setOpen(false);
              props.setLoading(true);
              // setAmount(1);
              toast.success(data.message);
              // setVoucher(1);
            } else {
              alert('missing or incorrect fields');
            }
          })
          .catch(function (error) {
            toast.error(error);
            console.log(error);
          });
        setVoucherAmt(0);
        props.setConfirm(false);
      })
      .catch(e => {
        console.log(e);
      });
    props.setConfirm(false);
  };

  const handleCloseConfirm = () => {
    props.setConfirm(false);
  };

  const downloadFile = async () => {
    firebase
      .auth()
      .currentUser.getIdToken()
      .then(async function (idToken) {
        const requestOptions = {
          method: 'POST',
          headers: {
            auth: idToken,
            responseType: 'blob',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            payment_file: props.payment_file,
            bus_file: props.bus_file,
          }),
        };

        const res = await fetch(
          process.env.REACT_APP_PROXY + '/user/downloadFile',
          requestOptions
        );
        const blob = await res.blob();

        download(blob, props.payment_file);
        // download(blob, props.bus_file);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const onFileSelect = e => {
    e.preventDefault();
    setFile(e.target.files[0]);
  };

  const onChangeDrop = (e, v, r) => {
    setVoucherSelect([...e]);
    setPayLater(false);
    var amountvoucher = 0;
    e.map(ele => {
      if (ele.label === 'Purchase more voucher') {
        setPayLater(true);
      } else {
        setPayLater(false);
      }
      amountvoucher += parseInt(ele.amount);
    });
    setVoucherAmt(amountvoucher);
  };

  function handleClick() {
    props.setPage('uniqegift');
  }
  const hiddenFileInput = React.useRef(null);
  const handleClickFile = event => {
    hiddenFileInput.current.click();
  };

  const donateNow = async () => {
    // alert(JSON.stringify(props));
    let data = {
      email: 'donors1@sammantrana.com',
      amount: amount,
      currency: 'SGD',
      // redirect_url: 'https://sponsor-a-student.io/',
      redirect_url: 'http://localhost:3000/',
    };
    await axios
      .post('https://api.sandbox.hit-pay.com/v1/payment-requests', data, {
        headers: {
          'X-BUSINESS-API-KEY':
            'fa676435477c5d181de8444931e7e1ae8fe2bf04eccf6559f2b8eab7376b6f84',
        },
      })
      .then(async res => {
        window.location.href = res.data.url;
      })
      .catch(e => {
        toast.error('Invalid API Key');
      });
  };
  const [loading, setLoading] = React.useState(false);
  const [later, setLater] = React.useState(false);
  const openInNewTab = url => {
    const newWindow = window.open(
      'https://' + url,
      '_blank',
      'noopener, noreferrer'
    );
    if (newWindow) newWindow.opener = null;
  };

  // ===============================My Voucher =======================
  const roles = needFilter;

  const donate = () => {
    if (!amount) {
      toast.error('Please Enter Amount ');
      return;
    }
    if (amount > props.requestAmt) {
      toast.error('Please do not donate more than the requested amount');
      return;
    }
    setLoading(true);
    const formData = new FormData();
    firebase
      .auth()
      .currentUser.getIdToken()
      .then(async function (idToken) {
        const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            auth: idToken,
          },
          body: JSON.stringify({
            // amount: amt2 > amount ? amt2 + oldAmount : Number(amount) + oldAmount,
            amount: amount,
            selected,
            later: later,
            need_type: props.need_type,
            student_id: props.student_id,
            reqAmt: props.requestAmt,
            application_no: props.application_no,
          }),
        };
        if (
          props.need_type === 'Tuition' ||
          props.need_type === 'Books' ||
          props.need_type === 'Pair Of Glasses'
        ) {
          const requestOptionsFile = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              auth: idToken,
            },
          };
          formData.append('payment', file);
          try {
            await axios
              .post(
                process.env.REACT_APP_PROXY + '/user/paymentSlip',
                formData,
                requestOptionsFile,
                {}
              )
              .then(async res => {
                setLoading(false);
                toast.success('File Uploaded Successfully');
              })
              .catch(err => {
                setLoading(false);
                console.log(err);
              });
          } catch (ex) {
            setLoading(false);
            toast.error(ex, { autoClose: 2000 });
          }
        }
        if (amt2 && amt2 < props.requestAmt - oldAmount) {
          props.setConfirm(true);
        } else {
          //donate
          fetch(process.env.REACT_APP_PROXY + '/user/donate', requestOptions)
            .then(res => res.json())
            .then(data => {
              if (data.success) {
                setLoading(false);
                props.setOpen(false);
                props.setLoading(true);
                setFile('');
                // setAmount(1);
                toast.success(data.message);
                // setVoucher(1);
              } else {
                setLoading(false);
                alert('missing or incorrect fields');
              }
            })
            .catch(function (error) {
              setLoading(false);
              toast.error(error);
              console.log(error);
            });
          setVoucherAmt(0);
        }
      });
  };
  const Option = props => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            styles={{ width: '285px !important' }}
            checked={props.isSelected}
            onChange={() => null}
          />{' '}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const styles = {
    contentContainer: {
      background: 'none',
      boxShadow: 'none',
      width: 1000,
      padding: 0,
      margin: 0,
      overflow: 'auto',
      // Hide scrollbar
      scrollbarWidth: 'none' /* Firefox */,
      '-ms-overflow-style': 'none' /* Internet Explorer 10+ */,
      '&::-webkit-scrollbar': {
        display: 'none' /* WebKit */,
      },
    },
  };

  return (
    <div>
      {props.confirmBox === true ? (
        <Dialog
          open={props.confirmBox}
          onClose={handleCloseConfirm}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Pay Later Confirmation
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You have partially fulfilled the request, would you like to
              sponsor remaining amount later?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleNo}>No</Button>
            <Button onClick={handleYes} autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        ''
      )}
      {loading && <Spinner />}
      {props.isNew ? (
        // <Dialog
        //   open={props.open}
        //   onClose={handleClose}
        //   className={classes.widthBox}
        //   aria-labelledby="form-dialog-title"
        // >
        //   <DialogTitle id="form-dialog-title">
        //     Sponsorship for Student
        //   </DialogTitle>
        //   <br />
        //   {/* <p style={{ color: 'red' }}>$30 Requested by Student</p> */}
        //   <div
        //     style={{
        //       marginLeft: 22,
        //       marginRight: 40,
        //       marginBottom: 10,
        //       marginTop: -35,
        //     }}
        //   >
        //     <br />
        //     <p style={{ marginLeft: '85%', marginBottom: '0px' }}>
        //       S$ {props.requestAmt}
        //     </p>
        //     <ProgressBar
        //       variant="dark"
        //       now={(props.fAmt / props.requestAmt) * 100}
        //       label={`${Math.floor(
        //         (props.fAmt / props.requestAmt) * 100
        //       )}%    Supported`}
        //     />
        //     <p style={{ marginLeft: '80%' }}>Amount Requested</p>
        //     {/* <br /> */}
        //     {/* <Checkbox
        //       onChange={e => setLater(e.target.checked)}
        //       checked={
        //         later === true || props.status === 'Later' ? true : false
        //       }
        //       value={later}
        //     /> */}
        //     <TextField
        //       variant="outlined"
        //       id="standard-search"
        //       type="search"
        //       onChange={e => setAmount(e.target.value)}
        //       placeholder="Enter Amount"
        //       label="S$ Enter Amount"
        //       style={{ marginTop: 14, width: 325 }}
        //     />{' '}
        //     {/* <DonationTimesSlider
        //       leftOver={props.leftOver}
        //       requestAmt={props.requestAmt}
        //       amount={amount}
        //       old={oldAmount}
        //       setAmount={setAmount}
        //     /> */}
        //     <br />
        //     {props.need_type === 'Tuition' ? (
        //       ''
        //     ) : (
        //       <>
        //         {/* <Typography
        //           color="primary"
        //           id="input-slider"
        //           style={{ marginTop: 14 }}
        //         >
        //           Amount
        //         </Typography>
        //         <TextField
        //           variant="outlined"
        //           id="standard-search"
        //           type="search"
        //           placeholder='Enter Amount'
        //           label="$"
        //           style={{ marginTop: 14, width: 285 }}
        //         />
        //         <br/> */}
        //       </>
        //     )}
        //     <br />
        //     {/* <br /> */}
        //     {props.need_type === 'Tuition' ||
        //     props.need_type === 'Books' ||
        //     props.need_type === 'Pair Of Glasses' ? (
        //       <div className="d-flex justify-content-around">
        //         {/* <span style={{ fontSize: '16px', fontWeight: 600 }}>
        //           For Payment: &nbsp;
        //         </span> */}
        //         <Button
        //           class="btn btn-outline-primary"
        //           onClick={() => openInNewTab(props.payment_url)}
        //         >
        //           Pay Here
        //         </Button>
        //         <br />
        //         <br />
        //         <Button
        //           class="btn btn-outline-primary"
        //           onClick={handleClickFile}
        //         >
        //           {' '}
        //           {file ? file.name.substring(0, 9) : 'Upload Receipt'}
        //           {/* Upload Invoice */}
        //           <input
        //             type="file"
        //             ref={hiddenFileInput}
        //             onChange={onFileSelect}
        //             style={{ display: 'none' }}
        //           />
        //         </Button>
        //       </div>
        //     ) : (
        //       ''
        //       // <>
        //       //   <Typography
        //       //     color="primary"
        //       //     id="input-slider"
        //       //     style={{ marginTop: 7 }}
        //       //   >
        //       //     Select Vouchers
        //       //   </Typography>
        //       //   {console.log(roles, 'rolesrolesroles =================================')}
        //       //   <ReactSelect
        //       //     options={roles}
        //       //     isMulti
        //       //     maxMenuHeight={100}
        //       //     // defaultValue={{label: "Purchase More Voucher", value:""}}
        //       //     style={{ marginTop: 18 }}
        //       //     closeMenuOnSelect={false}
        //       //     hideSelectedOptions={false}
        //       //     components={{
        //       //       Option,
        //       //     }}
        //       //     onChange={onChangeDrop}
        //       //     allowSelectAll={true}
        //       //     styles={{ width: '285px !important' }}
        //       //     // value={this.state.optionSelected}
        //       //   />
        //       //   <br />
        //       //   {payLater ? (
        //       //     <Typography
        //       //       color="primary"
        //       //       id="input-slider"
        //       //       style={{ marginTop: 7 }}
        //       //     >
        //       //       <button
        //       //         class="btn btn-dark"
        //       //         style={{ marginLeft: '40px', width: '200px' }}
        //       //         // color="primary"
        //       //         onClick={handleClick}
        //       //       >
        //       //         Purchase Now
        //       //       </button>
        //       //     </Typography>
        //       //   ) : (
        //       //     ''
        //       //   )}
        //       // </>
        //     )}
        //     <br />
        //     <br />
        //     {/* <Typography
        //       variant="caption"
        //       component="h2"
        //       gutterBottom
        //       align="justify"
        //     >
        //       sponsor-a-student.com relies on your generosity to
        //       <br /> continue providing 0% platform fee for fundraisers.
        //     </Typography> */}
        //   </div>
        //   <DialogActions>
        //     <Button onClick={handleClose} color="primary">
        //       Cancel
        //     </Button>
        //     {props.need_type !== 'Tuition' ||
        //     props.need_type !== 'Books' ||
        //     props.need_type !== 'Pair Of Glasses' ? (
        //       <Button
        //         onClick={donate}
        //         color="primary"
        //         disabled={file ? false : true}
        //       >
        //         Submit
        //       </Button>
        //     ) : (
        //       <Button
        //         onClick={donate}
        //         color="primary"
        //         disabled={file ? false : true}
        //       >
        //         Submit
        //       </Button>
        //     )}
        //   </DialogActions>
        // </Dialog>

        <Dialog
          open={props.open}
          onClose={handleClose}
          className={classes.widthBox}
          aria-labelledby="form-dialog-title"
          PaperProps={{
            style: styles.contentContainer,
          }}
        >
          <div className="admin-modal preferences-modal">
            <div className="modal-head">
              <div className="text-center">
                <h3>Sponsorship for Student</h3>
                <div className="d-flex justify-content-center">
                  <img
                    // src={`${process.env.REACT_APP_PROXY}/server/files/${props.payment_file}`}
                    src={`https://storage.cloud.google.com/prodprodsql/uploads/${props.payment_file}`}
                    width={100}
                    alt="Payment File"
                    className="my-3"
                  />
                </div>
              </div>
              <div className="form-bottom">
                <form>
                  <div className="form-group mb-0">
                    <span>Requested Amount: $ {props.requestAmt}</span>
                    <br />
                    <ProgressBar
                      variant="dark"
                      now={(props.fAmt / props.requestAmt) * 100}
                      label={`${Math.floor(
                        (props.fAmt / props.requestAmt) * 100
                      )}% Supported`}
                    />
                    <br />
                    <input
                      type="text"
                      placeholder="S$ Enter Amount"
                      onChange={e => setAmount(e.target.value)}
                    />
                  </div>
                  <div className="form-group btn-listing pay-receipt">
                    <button
                      type="button"
                      className="btn btn-border"
                      onClick={() => openInNewTab(props.payment_url)}
                    >
                      Pay Here
                    </button>
                    <div className="btn btn-border" onClick={handleClickFile}>
                      {file ? file.name.substring(0, 9) : 'Upload Receipt'}
                      <input
                        type="file"
                        ref={hiddenFileInput}
                        onChange={onFileSelect}
                        style={{ display: 'none' }}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <hr />
                  </div>
                  <div className="form-group btn-listing">
                    <div className="btn btn-border" onClick={handleClose}>
                      Cancel
                    </div>
                    <button
                      type="button"
                      className="btn btn-fill"
                      onClick={donate}
                      disabled={!file}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Dialog>
      ) : (
        <Dialog
          open={props.open}
          onClose={handleClose}
          className={classes.widthBox}
          aria-labelledby="form-dialog-title"
          PaperProps={{
            padding: 0,
            margin: 0,
          }}
        >
          <DialogTitle
            id="form-dialog-title"
            style={{ color: '#2998D1', fontWeight: 600 }}
          >
            {props.need_type}
          </DialogTitle>
          <div
            style={{
              marginLeft: 40,
              marginRight: 40,
              marginBottom: 10,
              marginTop: -20,
            }}
          >
            <Typography
              color="dark"
              id="input-slider"
              style={{ marginTop: 14 }}
            >
              Application Number : {props.application_no}
            </Typography>
            {props.invoice ? (
              <>
                <Typography
                  color="dark"
                  id="input-slider"
                  style={{ marginTop: 14 }}
                >
                  Invoice number : &nbsp;
                  {props.payment_file ? (
                    <Button color="primary" onClick={downloadFile}>
                      {props.invoice}
                    </Button>
                  ) : (
                    props.invoice
                  )}
                </Typography>
              </>
            ) : (
              ''
            )}
            {props.need_type === 'Tuition' ? (
              <>
                <Typography
                  color="dark"
                  id="input-slider"
                  style={{ marginTop: 14 }}
                >
                  University : {props.university}
                </Typography>
                <Typography
                  color="dark"
                  id="input-slider"
                  style={{ marginTop: 14 }}
                >
                  Year : {props.year}
                  &nbsp; &nbsp; Field : {props.field_of_study}
                </Typography>
              </>
            ) : (
              <Typography
                color="dark"
                id="input-slider"
                style={{ marginTop: 14 }}
              >
                Organization : &nbsp; &nbsp;{' '}
                {props.organization ? props.organization : '--'}
              </Typography>
            )}

            {/* {props.payment_url ? (
              <Typography
                color="dark"
                id="input-slider"
                style={{ marginTop: 14 }}
              >
                Payment URL : {props.payment_url}
              </Typography>
            ) : (
              ''
            )} */}

            {/* {props.payment_file ? (
              <Typography
                color="dark"
                id="input-slider"
                style={{ marginTop: 7 }}
              >
                Payment File :
                <Button onClick={downloadFile}>{props.payment_file}</Button>
              </Typography>
            ) : (
              ''
            )} */}

            {/* {props.bus_file ? (
              <Typography
                color="dark"
                id="input-slider"
                style={{ marginTop: 7 }}
              >
                Bursary File :
                <Button onClick={downloadFile}>{props.bus_file}</Button>
              </Typography>
            ) : (
              ''
            )} */}
          </div>
          <DialogActions>
            <Button
              style={{ marginRight: '135px' }}
              onClick={handleClose}
              color="primary"
            >
              Cancel
            </Button>
            <Button onClick={handleChangeDonate} color="primary">
              Donate
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}

//Shows the requests that student has made
export default function Request(props) {
  console.log('Props ---> ', props);
  const theme = useTheme();
  function LinearProgressWithLabel(props) {
    return (
      <Box display="flex" alignItems="center">
        <Box width="100%" mr={1}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box minWidth={35}>
          <Typography variant="body2" color="textSecondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }

  const classes = useStyles();
  const [newOpen, setNewOpen] = React.useState(false);
  const [isNew, setIsNew] = React.useState(false);
  const [confirmBox, setConfirm] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [leftOver, setLeftOver] = React.useState(0);
  const [requestAmt, setRequestAmt] = React.useState(1);
  const [application_no, setApplication_no] = React.useState(null);
  const [fileName, setFileName] = React.useState('');
  const [busfileName, setBusFileName] = React.useState('');
  const [university, setUniversity] = React.useState('');
  const [start_year, setStartyear] = React.useState('');
  const [field_of_study, setFeild] = React.useState('');
  const [payment_url, setPayUrl] = React.useState('');
  const [invoice, setInvoice] = React.useState('');
  const [need_type, setNeed] = React.useState('');
  const [student_id, setStudentId] = React.useState(null);
  // const [reqAmt, setReqAmt] = React.useState(null);
  const [full_amount, setFullAmt] = React.useState(0);
  const [status, setStatus] = React.useState('');
  const [row, setRow] = React.useState('');

  const [state, setState] = React.useState({
    columns: [],
    data: [],
    actions: [
      rowData => ({
        hidden: rowData.voucher_flag === true,
        width: 100,
        headerStyle: {
          backgroundColor: theme.palette.primary.main,
          color: 'white',
        },
        icon: () => (
          <PaymentIcon style={{ fontSize: '45px' }} color="secondary" />
        ),
        tooltip: 'Fulfill Now',
        onClick: (e, rowData) => {
          setLeftOver(rowData.need_amount - rowData.amount_fulfilled);
          setRequestAmt(rowData.need_amount);
          setFullAmt(rowData.amount_fulfilled);
          setStatus(rowData.status);
          openModal(rowData.application_no, rowData);
        },
      }),
    ],
  });

  const [loading1, setLoading1] = React.useState(true);
  const [voucher, setV] = React.useState('');
  const [stateCom, setStateCom] = React.useState({
    columns: [
      {
        field: '',
        title: 'Request Info',

        headerStyle: {
          backgroundColor: theme.palette.primary.main,
          color: 'white',
        },
        render: rowData => (
          <>
            {rowData.need_type === 'Tuition' ||
            rowData.need_type === 'Default' ? (
              <div>
                <Avatar
                  variant="square"
                  src={school}
                  className={classes.large}
                />
                <p style={{ marginLeft: '30px' }}>{rowData.need_type}</p>
              </div>
            ) : (
              ''
            )}
            {rowData.need_type === 'Housing' ? (
              <div>
                <Avatar
                  variant="square"
                  src={housing}
                  className={classes.large}
                />
                <p style={{ marginLeft: '30px' }}>{rowData.need_type}</p>
              </div>
            ) : (
              ''
            )}
            {rowData.need_type === 'Meal Plan' ? (
              <div>
                <Avatar
                  variant="square"
                  src={mealPlan}
                  className={classes.large}
                />
                <p style={{ marginLeft: '30px' }}>{rowData.need_type}</p>
              </div>
            ) : (
              ''
            )}
            {rowData.need_type === 'Books' ? (
              <div>
                <Avatar
                  variant="square"
                  src={books}
                  className={classes.large}
                />
                <p style={{ marginLeft: '30px' }}>{rowData.need_type}</p>
              </div>
            ) : (
              ''
            )}
            {rowData.need_type === 'Health Insurance' ? (
              <div>
                <Avatar
                  variant="square"
                  src={insurance}
                  className={classes.large}
                />
                <p style={{ marginLeft: '30px' }}>{rowData.need_type}</p>
              </div>
            ) : (
              ''
            )}
            {rowData.need_type === 'Air miles' ? (
              <div>
                <Avatar
                  variant="square"
                  src={airmiles}
                  className={classes.large}
                />
                <p style={{ marginLeft: '30px' }}>{rowData.need_type}</p>
              </div>
            ) : (
              ''
            )}
            {rowData.need_type === 'Laptop' ? (
              <div>
                <Avatar
                  variant="square"
                  src={laptop}
                  className={classes.large}
                />
                <p style={{ marginLeft: '30px' }}>{rowData.need_type}</p>
              </div>
            ) : (
              ''
            )}
            {rowData.need_type === 'Food and Beverage' ? (
              <div>
                <Avatar
                  variant="square"
                  src={mealPlan}
                  className={classes.large}
                />
                <p style={{ marginLeft: '30px' }}>
                  {rowData.need_type}
                  {rowData.food_option ? '(' + rowData.food_option + ')' : ''}
                </p>
              </div>
            ) : (
              ''
            )}
            {rowData.need_type === 'MRT' ? (
              <div>
                <Avatar variant="square" src={mrt} className={classes.large} />
                <p style={{ marginLeft: '30px' }}>{rowData.need_type}</p>
              </div>
            ) : (
              ''
            )}
            {rowData.need_type === 'Pair Of Glasses' ? (
              <div>
                <Avatar
                  variant="square"
                  src={glasses}
                  className={classes.large}
                />
                <p style={{ marginLeft: '30px' }}>{rowData.need_type}</p>
              </div>
            ) : (
              ''
            )}
            {rowData.need_type === 'Online Certification Courses' ? (
              <div>
                <Avatar
                  variant="square"
                  src={certification}
                  className={classes.large}
                />
                <p style={{ marginLeft: '30px' }}>{rowData.need_type}</p>
              </div>
            ) : (
              ''
            )}

            {rowData.need_type === 'Others' ? (
              <div>
                <Avatar
                  variant="square"
                  src={others}
                  className={classes.large}
                />
                <p style={{ marginLeft: '30px' }}>{rowData.need_type}</p>
              </div>
            ) : (
              ''
            )}
          </>
        ),
      },
      {
        title: 'Application Number',
        field: 'application_no',
        align: 'center',
        headerStyle: {
          backgroundColor: theme.palette.primary.main,
          color: 'white',
        },
      },
      {
        title: 'Status',
        field: 'status',
        headerStyle: {
          backgroundColor: theme.palette.primary.main,
          color: 'white',
        },
      },

      {
        title: 'Requested Amount',
        field: 'need_amount',
        align: 'center',
        type: 'currency',
        render: rowData => (
          <div>
            {' '}
            {rowData.need_type !== 'Air miles' ? '$' : ''}
            {rowData.need_amount}
            {rowData.need_type === 'Air miles' ? 'mi' : '.00'}
          </div>
        ),
        headerStyle: {
          backgroundColor: theme.palette.primary.main,
          color: 'white',
        },
      },

      {
        title: 'Donated Amount',
        field: 'amount',
        align: 'center',
        type: 'currency',
        render: rowData => (
          <div>
            {' '}
            {rowData.need_type !== 'Air miles' ? '$' : ''}
            {rowData.amount_fulfilled}
            {rowData.need_type === 'Air miles' ? 'mi' : '.00'}
          </div>
        ),
        headerStyle: {
          backgroundColor: theme.palette.primary.main,
          color: 'white',
        },
      },
      {
        title: 'Voucher Name',
        field: 'voucher_name',
        align: 'center',
      },
      {
        field: 'url',
        title: 'Fulfilled',
        align: 'center',
        headerStyle: {
          backgroundColor: theme.palette.primary.main,
          color: 'white',
        },
        render: rowData => (
          <LinearProgressWithLabel
            value={
              ((rowData.amount_fulfilled + 0.001) /
                (rowData.need_amount + 0.001)) *
              100
            }
          />
        ),
      },
    ],
    data: [],
  });

  const openModal = (app_no, rowData) => {
    setRow(rowData);
    if (app_no === 'new') {
      setIsNew(false);
    } else {
      setIsNew(true);
    }
    setFileName(rowData.payment_file);
    setBusFileName(rowData.bursary_file);
    setInvoice(rowData.invoice_number);
    setNeed(rowData.need_type);
    props.setData(rowData.need_type);
    setUniversity(rowData.university);
    setStartyear(rowData.start_year);
    setFeild(rowData.field_of_study);
    setPayUrl(rowData.payment_url);
    setApplication_no(rowData.application_no);
    setLeftOver(rowData.need_amount - rowData.amount_fulfilled);
    setRequestAmt(rowData.need_amount);
    setFullAmt(rowData.amount_fulfilled);
    setStatus(rowData.status);
    setNewOpen(true);
  };

  useEffect(() => {
    let fetchUrl = '';
    if (props.type === 'past') fetchUrl = '/user/application?need_type=past';
    else if (props.type === 'current') fetchUrl = '/user/application';
    else if (props.type === 'mine') fetchUrl = '/user/donation';
    if (loading) {
      firebase
        .auth()
        .currentUser.getIdToken()
        .then(function (idToken) {
          const getRequestOptions = {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              auth: idToken,
            },
          };
          fetch(process.env.REACT_APP_PROXY + fetchUrl, getRequestOptions)
            .then(res => res.json())
            .then(d => {
              if (d.success === false) {
              } else {
                if (d.message.length !== 0) {
                  // setFullAmt(d.message[0].amount_fulfilled);
                  setStudentId(d.message[0].uid);
                  // setRequestAmt(d.message[0].need_amount);
                }
                setLoading(false);
                // setLoading(false);
                setState(prevState => {
                  if (typeof d.message.filter !== 'undefined') {
                    const data = d.message.filter(row =>
                      !props.inactive
                        ? row.status === 'Active' || row.status === 'Later'
                        : row.status === 'Fullfilled'
                    );
                    return { ...prevState, data };
                  }

                  return { ...prevState };
                });
              }
            })
            .catch(err => {
              console.log(err);
            });
          fetch(
            process.env.REACT_APP_PROXY + '/user/donation',
            getRequestOptions
          )
            .then(res => res.json())
            .then(d => {
              if (d.success === false) {
              } else {
                setLoading1(false);
                setStateCom(prevState => {
                  if (typeof d.message.filter !== 'undefined') {
                    const data1 = d.message.filter(
                      row => row.status === 'Fullfilled' || 'Inactive'
                    );
                    console.log('My data1 1452', data1);
                    setV(data1);
                    let uniqueObjArray = [
                      ...new Map(
                        data1.map(item => [item['application_no'], item])
                      ).values(),
                    ];
                    const data = uniqueObjArray;
                    console.log('My data1 1460', data);
                    return { ...prevState, data };
                  }

                  return { ...prevState };
                });
              }
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [loading, loading1]);

  const [requestPage, setRequestPage] = useState(0);
  const [requestRowsPerPage, setRequestRowsPerPage] = useState(3);
  const [fulfilledPage, setFulfilledPage] = useState(0);
  const [fulfilledRowsPerPage, setFulfilledRowsPerPage] = useState(3);

  const handleRequestChangePage = (event, newPage) => {
    setRequestPage(newPage);
  };

  const handleRequestChangeRowsPerPage = event => {
    setRequestRowsPerPage(parseInt(event.target.value, 10));
    setRequestPage(0);
  };

  const handleFulfilledChangePage = (event, newPage) => {
    setFulfilledPage(newPage);
  };

  const handleFulfilledChangeRowsPerPage = event => {
    setFulfilledRowsPerPage(parseInt(event.target.value, 10));
    setFulfilledPage(0);
  };

  const formatDate = dateString => {
    const date = new Date(dateString);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

  //   -----------***** Table data for Requests Status ****** ----------------------
  const requestStatusColumns = [
    'Request Info',
    'Fulfill By Date',
    'Status',
    'Requested Amount',
    'Fulfilled Amount',
    'Fulfilled',
    'Donate',
  ];
  const renderRequestStatusRow = item => (
    <>
      <td>
        <div className="text-img-table">
          <div className="img-table">
            {item.need_type === 'Books' && (
              <img src={books} alt="Books" width="60" />
            )}
            {item.need_type === 'Tuition' && (
              <img src={school} alt="School" width="60" />
            )}
            {item.need_type === 'Pair Of Glasses' && (
              <img src={glasses} alt="Pair Of Glasses" width="60" />
            )}
          </div>
          <div className="text-table">
            {item.need_type} <span>#{item.application_no}</span>
          </div>
        </div>
      </td>
      <td>{formatDate(item.need_dateline)}</td>
      <td>
        <span className="active-dot" />
        {item.status}
      </td>
      <td>${item.need_amount}.00</td>
      <td>${item.amount_fulfilled}.00</td>
      <td>
        <LinearProgressWithLabel
          value={
            ((item.amount_fulfilled + 0.001) / (item.need_amount + 0.001)) * 100
          }
        />
      </td>
      <td>
        <div onClick={() => openModal(item.application_no, item)}>
          <svg
            width="36"
            height="28"
            style={{ cursor: 'pointer' }}
            viewBox="0 0 36 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M32 3H4C3.375 3.04167 3.04167 3.375 3 4V6H33V4C32.9583 3.375 32.625 3.04167 32 3ZM33 12H3V24C3.04167 24.625 3.375 24.9583 4 25H32C32.625 24.9583 32.9583 24.625 33 24V12ZM4 0H32C33.125 0.0416667 34.0625 0.4375 34.8125 1.1875C35.5625 1.9375 35.9583 2.875 36 4V24C35.9583 25.125 35.5625 26.0625 34.8125 26.8125C34.0625 27.5625 33.125 27.9583 32 28H4C2.875 27.9583 1.9375 27.5625 1.1875 26.8125C0.4375 26.0625 0.0416667 25.125 0 24V4C0.0416667 2.875 0.4375 1.9375 1.1875 1.1875C1.9375 0.4375 2.875 0.0416667 4 0ZM7.5 19H10.5C11.4167 19.0833 11.9167 19.5833 12 20.5C11.9167 21.4167 11.4167 21.9167 10.5 22H7.5C6.58333 21.9167 6.08333 21.4167 6 20.5C6.08333 19.5833 6.58333 19.0833 7.5 19ZM15.5 19H22.5C23.4167 19.0833 23.9167 19.5833 24 20.5C23.9167 21.4167 23.4167 21.9167 22.5 22H15.5C14.5833 21.9167 14.0833 21.4167 14 20.5C14.0833 19.5833 14.5833 19.0833 15.5 19Z"
              fill="#00775A"
            />
          </svg>
        </div>
      </td>
    </>
  );

  //   -----------***** Table data for Fullfilled Status ****** ----------------------

  const fulfilledColumns = [
    'Request Info',
    'Application Number',
    'Status',
    'Requested Amount',
    'Donated Amount',
    'Fulfilled',
  ];

  const renderFulfilledRow = rowData => (
    <>
      <td>
        <div className="text-img-table">
          <div className="img-table">
            {rowData.need_type === 'Books' && (
              <>
                <img src={books} alt="Books" width="60" />
              </>
            )}
            {rowData.need_type === 'Tuition' && (
              <>
                <img src={school} alt="School" width="60" />
              </>
            )}
            {rowData.need_type === 'Pair Of Glasses' && (
              <>
                <img src={glasses} alt="Pair Of Glasses" width="60" />
              </>
            )}
          </div>
          <div className="text-table">{rowData.need_type}</div>
        </div>
      </td>
      <td>{rowData.application_no}</td>
      <td>{rowData.status}</td>
      <td>${rowData.need_amount}</td>
      <td>${rowData.amount_fulfilled}</td>
      <td>
        <LinearProgressWithLabel
          value={
            ((rowData.amount_fulfilled + 0.001) /
              (rowData.need_amount + 0.001)) *
            100
          }
        />
      </td>
    </>
  );

  return (
    <>
      <div>
        {/* <Container maxWidth="lg" component="main" style={{ paddingTop: 90 }}>
        <Typography
          variant="body1"
          paragraph
          align="center"
          style={{ fontSize: '22px' }}
        >
          Sponsorship Requests
        </Typography>
        <MaterialTable
          isLoading={loading}
          icons={tableIcons}
          title=""
          localization={{
            header: {
              actions: 'Donate',
            },
          }}
          options={{
            actionsColumnIndex: -1,
            search: false,
            pageSize: 3,
            pageSizeOptions: [3, 5, 10, 20],
            toolbar: false,
            paging: true,
            headerStyle: {
              backgroundColor: theme.palette.primary.main,
              color: 'white',
            },
            actionsCellStyle: {
              minWidth: 80,
            },
          }}
          columns={state.columns}
          data={state.data}
          actions={state.actions}
        />
        <GiveBackDialog
          open={newOpen}
          fAmt={full_amount}
          status={status}
          row={row}
          setPage={props.setPage}
          setOpen={setNewOpen}
          setIsNew={setIsNew}
          setConfirm={setConfirm}
          confirmBox={confirmBox}
          isNew={isNew}
          leftOver={leftOver}
          requestAmt={requestAmt}
          student_id={student_id}
          // reqAmt={reqAmt}
          payment_file={fileName}
          invoice={invoice}
          need_type={need_type}
          bus_file={busfileName}
          payment_url={payment_url}
          field_of_study={field_of_study}
          university={university}
          year={start_year}
          application_no={application_no}
          setLoading={setLoading}
        />
      </Container> */}
        {/* <Container maxWidth="lg" component="main" style={{ paddingTop: 90 }}>
        <Typography
          variant="body1"
          paragraph
          align="center"
          style={{ fontSize: '22px' }}
        >
          Fulfiled Requests
        </Typography>
        <MaterialTable
          isLoading={loading1}
          icons={tableIcons}
          title=""
          localization={{
            header: {
              actions: 'Donate',
            },
          }}
          detailPanel={[
            {
              tooltip: 'Show Details',
              render: rowData => {
                const data = JSON.parse(JSON.stringify(voucher));
                return (
                  <>
                    <MaterialTable
                      columns={[
                        { title: 'Application No', field: 'application_no' },
                        { title: 'Need Type', field: 'need_type' },
                        { title: 'Voucher Name', field: 'voucher_name' },
                      ]}
                      // actions={state.actions}
                      icons={tableIcons}
                      data={data.filter(
                        row => row.application_no === rowData.application_no
                      )}
                      options={{
                        actionsColumnIndex: -1,
                        search: false,
                        pageSize: 5,
                        pageSizeOptions: [5, 10, 20, 30, 50, 75, 100],
                        toolbar: false,
                        paging: false,
                        actionsCellStyle: {
                          minWidth: 80,
                        },
                      }}
                    />
                  </>
                );
              },
            },
          ]}
          options={{
            actionsColumnIndex: -1,
            search: false,
            pageSize: 3,
            pageSizeOptions: [3, 5, 10, 20],
            toolbar: false,
            paging: true,
            headerStyle: {
              backgroundColor: theme.palette.primary.main,
              color: 'white',
            },
            actionsCellStyle: {
              minWidth: 80,
            },
          }}
          columns={stateCom.columns}
          data={stateCom.data}
        />
      </Container> */}
        <>
          {/* ================ ----------> Request Status <-----------===================== */}
          <section className="sponsorship-requests-section">
            <div className="container">
              <div className="mb-2">
                <b className="fs-4 ">Requests Status</b>
              </div>
              <div className="shadow-box">
                <div className="table-top table-sec">
                  <Table
                    columns={requestStatusColumns}
                    rows={state.data}
                    page={requestPage}
                    rowsPerPage={requestRowsPerPage}
                    handleChangePage={handleRequestChangePage}
                    handleChangeRowsPerPage={handleRequestChangeRowsPerPage}
                    renderRow={renderRequestStatusRow}
                    loading={loading}
                  />
                </div>
              </div>
            </div>
          </section>
        </>
        <GiveBackDialog
          open={newOpen}
          fAmt={full_amount}
          status={status}
          row={row}
          setPage={props.setPage}
          setOpen={setNewOpen}
          setIsNew={setIsNew}
          setConfirm={setConfirm}
          confirmBox={confirmBox}
          isNew={isNew}
          leftOver={leftOver}
          requestAmt={requestAmt}
          student_id={student_id}
          // reqAmt={reqAmt}
          payment_file={fileName}
          invoice={invoice}
          need_type={need_type}
          bus_file={busfileName}
          payment_url={payment_url}
          field_of_study={field_of_study}
          university={university}
          year={start_year}
          application_no={application_no}
          setLoading={setLoading}
        />
        {/* ================ ----------> FullFilled Request <-----------===================== */}
        <section className="sponsorship-requests-section">
          <div className="container">
            <div className="mb-2">
              <b className="fs-4">Fulfilled Requests</b>
            </div>
            <div className="shadow-box">
              <div className="table-bottom table-sec">
                <Table
                  columns={fulfilledColumns}
                  rows={stateCom.data}
                  page={fulfilledPage}
                  rowsPerPage={fulfilledRowsPerPage}
                  handleChangePage={handleFulfilledChangePage}
                  handleChangeRowsPerPage={handleFulfilledChangeRowsPerPage}
                  renderRow={renderFulfilledRow}
                  loading={loading}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
