import React from 'react';

const Cards = (props) => {
  return (
    <>
      <div className="col-lg-3 col-md-6 col-sm-12 mb-4">
        <div className="main_card">
          <img src={props.img} alt="imgIcon" width={60} />
          <div>
            <h4><b>{props.number}</b></h4>
            <p> {props.title} </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cards;
