// Functionality of this code : This page contains the University Request table section
// Input : None
// Output : University Request table
// Written by: Initial team VK, KR

import React, { useEffect } from 'react';
import MaterialTable from 'material-table';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import * as firebase from 'firebase';
import Container from '@material-ui/core/Container';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import Grid from '@material-ui/core/Grid';
import Slider from '@material-ui/core/Slider';
import PaymentIcon from '@material-ui/icons/Payment';
import certification from '../assets/certification.jpg';
import airmiles from '../assets/airmiles.jpg';
import mealPlan from '../assets/mealPlan.jpg';
import insurance from '../assets/insurance.jpg';
import school from '../assets/icon1.png';
import laptop from '../assets/laptop.jpg';
import housing from '../assets/housing.jpg';
import books from '../assets/icon2.png';
import others from '../assets/others.jpg';
import tableIcons from '../admin/MaterialTableIcons';
import glasses from '../assets/icon3.png';

const useStyles = makeStyles(theme => ({

  root: {
    
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  heading:{
    color:'#77000D'
  }
}));

const DonationTimesSlider = props => {
  const [value, setValue] = React.useState(1);
  const handleSliderChange = (event, newValue) => {
    props.setAmount(newValue);
  };
  const useStyles2 = makeStyles({
    root: {
      width: 250,
      marginTop: 20,
    },
    input: {
      width: 42,
    },
  });
  const classes = useStyles2();
  return (
    <div className={classes.root}>
      <Typography color="primary" id="input-slider" gutterBottom>
        Amount willing to sponsor
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <PaymentIcon />
        </Grid>
        <Grid item xs>
          <Slider
            min={1}
            step={1}
            max={props.leftOver}
            valueLabelDisplay="auto"
            value={props.amount}
            onChange={handleSliderChange}
            aria-labelledby="input-slider"
          />
        </Grid>
        <Grid item>
          <Typography id="input-slider" gutterBottom>
            ${props.amount}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

function GiveBackDialog(props) {
  const [amount, setAmount] = React.useState(1);
  const [error, setError] = React.useState('');
  const handleClose = () => {
    props.setOpen(false);
    setAmount(1);
  };
  const donate = () => {
    firebase
      .auth()
      .currentUser.getIdToken()
      .then(function (idToken) {
        const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            auth: idToken,
          },
          body: JSON.stringify({
            amount,
            application_no: props.application_no,
          }),
        };

        fetch(process.env.REACT_APP_PROXY + '/user/donate', requestOptions)
          .then(res => res.json())
          .then(data => {
            if (data.success) {
              props.setOpen(false);
              props.setLoading(true);
              setAmount(1);
            } else {
              setError('missing or incorrect fields');
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      });
  };
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          ${props.leftOver} left to completion
        </DialogTitle>

        <div style={{ marginLeft: 40, marginRight: 40, marginBottom: 10 }}>
          <DonationTimesSlider
            leftOver={props.leftOver}
            amount={amount}
            setAmount={setAmount}
          />
        </div>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={donate} color="primary">
            Donate
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

//School's view as they have to see all requests of their students
export default function AllRequest(props) {
  const theme = useTheme();
  function LinearProgressWithLabel(props) {
    return (
      <Box display="flex" alignItems="center">
        <Box width="100%" mr={1}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box minWidth={35}>
          <Typography variant="body2" color="textSecondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }



  const classes = useStyles();
  const [newOpen, setNewOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [leftOver, setLeftOver] = React.useState(1);
  const [application_no, setApplication_no] = React.useState(null);
  const [error, setError] = React.useState('');
  
  


  const [state, setState] = React.useState({
    mainColumn: [
      {
        field: '',
        title: 'Need type',

        headerStyle: {
          backgroundColor: theme.palette.primary.main,
          color: 'white',
        },
        render: rowData => (
          <>
            {rowData.need_type === 'Tuition' ||
            rowData.need_type === 'Default' ? (
              <Avatar variant="square" src={school} className={classes.large} />
            ) : (
              ''
            )}
            {rowData.need_type === 'Housing' ? (
              <Avatar
                variant="square"
                src={housing}
                className={classes.large}
              />
            ) : (
              ''
            )}
            {rowData.need_type === 'Food and Beverage' ? (
              <Avatar
                variant="square"
                src={mealPlan}
                className={classes.large}
              />
            ) : (
              ''
            )}
            {rowData.need_type === 'Books' ? (
              <Avatar variant="square" src={books} className={classes.large} />
            ) : (
              ''
            )}
            {rowData.need_type === 'Pair Of Glasses' ? (
              <Avatar variant="square" src={glasses} className={classes.large} />
            ) : (
              ''
            )}
            {rowData.need_type === 'Health Insurance' ? (
              <Avatar
                variant="square"
                src={insurance}
                className={classes.large}
              />
            ) : (
              ''
            )}
            {rowData.need_type === 'Air miles' ? (
              <Avatar
                variant="square"
                src={airmiles}
                className={classes.large}
              />
            ) : (
              ''
            )}
            {rowData.need_type === 'Laptop' ? (
              <Avatar variant="square" src={laptop} className={classes.large} />
            ) : (
              ''
            )}
            {rowData.need_type === 'Online Certification Courses' ? (
              <Avatar
                variant="square"
                src={certification}
                className={classes.large}
              />
            ) : (
              ''
            )}

            {rowData.need_type === 'Others' ? (
              <Avatar variant="square" src={others} className={classes.large} />
            ) : (
              ''
            )}
          </>
        ),
      },
      {
        title: 'Total number of requests',
        field: 'requestsCount',
        type: 'numeric',
        align: 'left',
      },
      {
        title: 'Total Amount',
        field: 'requestsAmount',
        type: 'currency',
        render: rowData => (
          <div>
            {' '}
            {rowData.need_type !== 'Air miles' ? '$' : ''}
            {rowData.requestsAmount}
            {rowData.need_type === 'Air miles' ? 'mi' : '.00'}
          </div>
        ),
        align: 'left',
      }
    ],
    columns: [
      {
        title: 'Student ID',
        field: 'student_id',
      },
      {
        title: 'Date',
        field: 'request_date',
        type: 'date',
      },
      {
        title: 'Application number',
        field: 'application_no',
      },
      {
        title: 'Status',
        field: 'status',
      },
      {
        title: 'Requested Amount',
        field: 'need_amount',
        type: 'currency',
        align: 'left',
        render: rowData => (
          <div>
            {' '}
            {rowData.need_type !== 'Air miles' ? '$' : ''}
            {rowData.need_amount}
            {rowData.need_type === 'Air miles' ? 'mi' : '.00'}
          </div>
        ),
      },
      {
        field: 'url',
        title: 'Fullfilled',
        render: rowData => (
          <LinearProgressWithLabel
            value={
              ((rowData.amount_fulfilled + 0.0001) /
                (rowData.need_amount + 0.0001)) *
              100
            }
          />
        ),
      },
    ],
    data: [],
    rawData: [],
    actions: [
      rowData => ({
        hidden: rowData.need_amount - rowData.amount_fulfilled === 0,
        width: 100,
        headerStyle: {
          backgroundColor: theme.palette.primary.main,
          color: 'white',
        },
        icon: () => <ThumbUpIcon color="secondary" />,
        tooltip: 'Approve',
        onClick: (e, rowData) => {},
      }),
      rowData => ({
        hidden: rowData.need_amount - rowData.amount_fulfilled === 0,
        width: 100,
        headerStyle: {
          backgroundColor: theme.palette.primary.main,
          color: 'white',
        },
        icon: () => (
          <ThumbDownIcon color="secondary" style={{ color: '#c2185b' }} />
        ),
        tooltip: 'Disapprove',
        onClick: (e, rowData) => {}
      }),
    ],
  });

  const openModal = app_no => {
    setApplication_no(app_no);
    setNewOpen(true);
  };
  useEffect(() => {
    const fetchUrl = '/user/application';

    if (loading) {
      firebase
        .auth()
        .currentUser.getIdToken()
        .then(function (idToken) {
          const getRequestOptions = {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              auth: idToken,
            },
          };
          fetch(process.env.REACT_APP_PROXY + fetchUrl, getRequestOptions)
            .then(res => res.json())
            .then(d => {
              console.log(d);
              if (d.success === false) {
                setError('failed');
              } else {
                setLoading(false);
                const totalData = {
                  Tuition: {
                    need_type: 'Tuition',
                    requestsCount: 0,
                    requestsAmount: 0,
                  },
                  Housing: {
                    need_type: 'Housing',
                    requestsCount: 0,
                    requestsAmount: 0,
                  },
                  'Food and Beverage': {
                    need_type: 'Food and Beverage',
                    requestsCount: 0,
                    requestsAmount: 0,
                  },
                  Books: {
                    need_type: 'Books',
                    requestsCount: 0,
                    requestsAmount: 0,
                  },
                  'Health Insurance': {
                    need_type: 'Health Insurance',
                    requestsCount: 0,
                    requestsAmount: 0,
                  },
                  'Air miles': {
                    need_type: 'Air miles',
                    requestsCount: 0,
                    requestsAmount: 0,
                  },
                  Laptop: {
                    need_type: 'Laptop',
                    requestsCount: 0,
                    requestsAmount: 0,
                  },
                  'Online Certification Courses': {
                    need_type: 'Online Certification Courses',
                    requestsCount: 0,
                    requestsAmount: 0,
                  },
                  'Pair Of Glasses': {
                    need_type: "Pair Of Glasses",
                    requestsCount: 0,
                    requestsAmount: 0,
                  }
                };
                const reducer = (accumulator, item) => {
                  accumulator[item.need_type].requestsCount += 1;
                  accumulator[item.need_type].requestsAmount +=item.need_amount;
                  return accumulator;
                };

            
                
                const data = Object.values(
                  d.message
                    .filter(row => row.status == props.type)
                    .reduce(reducer, totalData)
                );
                const data2 = data.filter(row => row.requestsCount > 0);
                setState(prevState => ({
                  ...prevState,
                  rawData: d.message.filter(row => row.status == props.type),
                  data: data2
                }));
              }
            });
        });
    }
  }, [loading]);

  // console.log("MY ROW DATA ===>" , state);
  
  return (
    <div className="sponsorship-requests-section">
      <div
        className="container"
      >
      <div
        className="shadow-box"
      >
           <div className="table-bottom table-sec">
              <h2>Requests Status</h2>
              <div className="table-box">
                <div className="scroll-table-box">
        
                {loading ? (
                    <LinearProgress />
                  ) :(
                    <MaterialTable
                   
        
                    detailPanel={[
                      {
                        tooltip: 'Show Name',
                        render: rowData => {
                          const data = JSON.parse(JSON.stringify(state.rawData))
                          return (
                            <>
                              <MaterialTable
                              
                                columns={[
                                  {
                                    title: 'Student ID',
                                    field: 'student_id'
                                  },
                                  {
                                    title: 'Date',
                                    field: 'request_date',
                                    type: 'date'
                                  },
                                  {
                                    title: 'Application number',
                                    field: 'application_no'
                                  },
                                  {
                                    title: 'Status',
                                    field: 'status'
                                  },
                                  {
                                    title: 'Requested Amount',
                                    field: 'need_amount',
                                    type: 'currency',
                                    align: 'left',
                                    render: rowData => (
                                      <div>
                                        {' '}
                                        {rowData.need_type !== 'Air miles' ? '$' : ''}
                                        {rowData.need_amount}
                                        {rowData.need_type === 'Air miles' ? 'mi' : '.00'}
                                      </div>
                                    ),
                                  },
                                  {
                                    field: 'url',
                                    title: 'Fullfilled',
                                    render: rowData => (
                                      <LinearProgressWithLabel
                                        value={
                                          ((rowData.amount_fulfilled + 0.0001) /
                                            (rowData.need_amount + 0.0001)) *
                                          100
                                        }
                                      />
                                    )
                                  }
                                ]}
                                actions={state.actions}
                                icons={tableIcons}
                                data={data.filter(
                                  row => row.need_type === rowData.need_type
                                )}
                                options={{
                                  actionsColumnIndex: -1,
                                  search: false,
                                  pageSize: 5,
                                  pageSizeOptions: [5, 10, 20, 30, 50, 75, 100],
                                  toolbar: false,
                                  paging: true,
                                  actionsCellStyle: {
                                    minWidth: 80,
                                  }
                                }}
                              />
                            </>
                          );
                        }
                      }
                    ]}
                    isLoading={loading}
                    title=""
                    localization={{
                      header: {
                        actions: 'Approval',
                      }
                    }}
                    options={{
                      actionsColumnIndex: -1,
                      search: false,
                      pageSize: 3,
                      pageSizeOptions: [3 ,5, 10, 20, 30, 50, 75, 100],
                      toolbar: false,
                      paging: true,
                      headerStyle: {
                        backgroundColor: '#77000D',
                        color: 'white',
                      },
                      actionsCellStyle: {
                        minWidth: 80,
                      }
                    }}
                    // columns={state.mainColumn}
                    columns={state.mainColumn.map(column => ({
                      ...column,
                      headerStyle: {
                        backgroundColor: '#77000D',
                        fontSize:'1.2rem'
                      }
                    }))}
                    data={state.data}
                    icons={tableIcons}
                  />
                  )}
      </div> 
       </div>
       </div>
       </div>
       </div>
      <GiveBackDialog
        open={newOpen}
        setOpen={setNewOpen}
        leftOver={leftOver}
        application_no={application_no}
        setLoading={setLoading}
      />
    </div>
  
  );
}
