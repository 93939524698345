// Functionality of this code : This is the landing page of the application
// Input : None
// Output : Landing Page

import React, { useState } from 'react';
import * as firebase from 'firebase';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Link } from '@material-ui/core';
import UserProfile from './UserProfile';
import google_login from './assets/google_login.svg';
import poster2 from './assets/poster-new.jpg';
// import home1 from './assets/home1.jpg'
import home1 from './assets/temp.png';
// import { toast } from 'react-toastify';
import toast from 'react-hot-toast';
import 'react-toastify/dist/ReactToastify.css';
// import { GoogleLogin } from 'react-google-login';
import Spinner from './utils/spinner';
import secureLocalStorage from 'react-secure-storage';

// import PasswordChecklist from 'react-password-checklist';

const responseGoogle = response => {
  console.log(response);
};

// toast.configure();

const roles = [
  { title: 'Student' },
  { title: 'Sponsor' },
  { title: 'Organization' },
];

// Defining the page style sheet
const useStyles = makeStyles(theme => ({
  card: {
    display: 'flex',
    borderRadius: '20px',
    boxShadow: '0 0 5px #a1a1a1',
    // '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
    padding: '50px 20px 50px 50px',
    '@media (max-width: 991px)': {
      padding: '30px 0px 30px 30px',
    },
    '@media (max-width: 767px)': {
      padding: '0px',
    },
  },

  cardDetails: {
    flex: 1,
  },

  cardMedia: {
    width: 800,
  },

  image: {
    backgroundImage: `url(${poster2})`,
    height: '800px',
    marginTop: '60px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'left',
  },

  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '@media (max-width: 767px)': {
      margin: '30px 15px',
    },
  },

  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },

  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },

  submit: {
    margin: theme.spacing(3, 0, 1),
  },

  button: {
    margin: theme.spacing(1),
  },

  large: {
    width: theme.spacing(8),
    height: theme.spacing(8),
  },

  customcontainer: {
    maxWidth: '1365px',
  },

  container: {
    maxWidth: '1365px',
    paddingTop: '40px',
    '@media (max-width: 767px)': {
      marginTop: '60px',
    },
  },

  spacing: {
    padding: '95px 0',
    '@media (max-width: 900px)': {
      padding: '50px 0',
    },
    '@media (max-width: 767px)': {
      padding: '30px 0',
    },
  },

  spacing1: {
    padding: '95px 0 0px',
    '@media (max-width: 900px)': {
      padding: '50px 0 0px',
    },
    '@media (max-width: 767px)': {
      padding: '30px 0 0px',
    },
  },
  customTextField: {
    borderRadius: '20px',
  },
}));

//Login/Register/SSO Page
export default function Authentication(props) {
  const classes = useStyles();

  // initiliz state
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [role, setRole] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [confirmPass, setConfirmPass] = React.useState('');
  const [currentPage, setCurrentPage] = React.useState('login');
  const [accesscode, SetAccessCoupan] = React.useState('');
  const [passwordShown, setPasswordShown] = React.useState(false);
  const [passValid, setPassValid] = React.useState(false);
  const [university, setUniversity] = React.useState([]);
  const [selectUni, setSelectedUni] = React.useState('');
  const [error, setError] = React.useState('');
  const [Emailvalid, setEValid] = React.useState(false);
  const [Passvalid, setPValid] = React.useState(false);
  const [PassCvalid, setPCValid] = React.useState(false);
  const [Rolevalid, setRoleValid] = React.useState(false);
  const [Accessvalid, setAcessValid] = React.useState(false);
  const [Orgvalid, setOrgValid] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  // Email verification functionality
  const [otp, setOtp] = useState('');
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [otpError, setOtpError] = useState('');
  const [isOtpInputVisible, setIsOtpInputVisible] = useState(false);
  const [modalEmail, setModalEmail] = useState('');

  const [isOtpVerified, setIsOtpVerified] = useState(false);

  require('dotenv').config();

  const toggleLoginRegister = () => {
    setError('');
    setEmail('');
    SetAccessCoupan('');
    setPassword('');
    setRole('');
    setConfirmPass('');
    setEValid(false);
    setPValid(false);
    setRoleValid(false);
    setOrgValid(false);
    setAcessValid(false);
    setPCValid(false);
    if (currentPage === 'login') {
      setCurrentPage('register');
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      };
      //================== Fetching University ============================
      fetch(process.env.REACT_APP_PROXY + '/data/universities', requestOptions)
        .then(res => res.json())
        .then(data => {
          let resp = data.data;
          let tempArr = [];
          // let tempArr1 = [];
          let tempArr2 = [];
          resp.map(element => {
            // tempArr1.push(element.name);
            tempArr2.push(element.org_name);
          });
          if (data.success === false) {
            setError(data.message);
          } else {
            tempArr = tempArr2;
            const unique = [...new Set(tempArr)];
            setUniversity(unique);
          }
        });
    } else setCurrentPage('login');
  };

  //show hide password
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  //switch login and registration tab
  const LoginOrRegister = () => {
    if (currentPage === 'login') onLogIn();
    else onRegister();
  };

  const onLogIn = () => {
    //validation
    if (!email && !password) {
      setError('Please enter details');
      setEValid(true);
      setPValid(true);
      return;
    }

    if (!email) {
      setError('Please enter an email address.');
      setEValid(true);
      return;
    }
    if (!password) {
      setError('Please enter a password.');
      setPValid(true);
      return;
    }

    if (password.length < 4) {
      setError('password (min length 5)');
      setPValid(true);
      return;
    }

    if (email !== 'admin@sammantrana.com') {
      setLoading(true);
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(user => {
          const idToken = user.user._lat;
          const requestOptions = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              auth: idToken,
            },
            body: JSON.stringify({
              role: 'student',
            }),
          };
          if (!user.user.emailVerified) {
          }
          //set email
          UserProfile.setName(user.user.email);
          //fetch data for role
          fetch(process.env.REACT_APP_PROXY + '/user/auth', requestOptions)
            .then(res => res.json())
            .then(data => {
              if (data.success === true) {
                if (data.profile === true) {
                  if (data.role === 'student') {
                  } else if (data.role === 'sponsor') {
                  } else {
                  }
                } else {
                }
                if (data.role === 'organization') {
                } else if (data.role === 'student') {
                } else if (data.role === 'sponsor') {
                }
                toast.success('Login Success', {
                  autoClose: 2000,
                  style: {
                    marginTop: '30px',
                  },
                });
                setLoading(false);
              }
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(function (error) {
          // Handle Errors here.
          const errorCode = error.code;
          setLoading(false);
          const errorMessage =
            'No user found corresponding to this identifier. Please signup for start.';
          // [START_EXCLUDE]
          if (errorCode === 'auth/wrong-password') {
            //setError('Wrong password.');
            toast.error('Wrong password', {
              autoClose: 2000,
              style: {
                marginTop: '30px',
              },
            });
          } else {
            setError(errorMessage);
            toast.error('Wrong email or password', {
              autoClose: 2000,
              style: {
                marginTop: '30px',
              },
            });
          }
          // [END_EXCLUDE]
        });
    } else {
      setLoading(false);
      toast.error('No user found corresponding to this identifier', {
        autoClose: 2000,
        style: {
          marginTop: '30px',
        },
      });
    }
    // Sign in with email and pass.
    // [START authwithemail]
  };

  // ---------------------------------------**************OTP Functionality *************  ---------------------------------------------

  // ------ check user is already exist or not
  async function checkUserExists(email) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_PROXY}/checkemail?email=${email}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      const data = await response.json();

      if (data.exists) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error('Error checking user existence:', error);
      toast.error('An error occurred while checking user existence.');
      return null;
    }
  }

  // Valid OTP function
  const validateOtp = () => {
    const otpRegex = /^[0-9]{6}$/;
    return otpRegex.test(otp);
  };

  // === ** Send OTP *** ====
  const sendOtp = async () => {
    if (!validateEmail(email)) {
      setError('Please enter a valid email address.');
      setEValid(true);
      return;
    }
    try {
      setLoading(true);
      const exists = await checkUserExists(email);
      console.log('exist ', exists);

      if (exists) {
        toast.error('Account already exists');
        setLoading(false);
      } else if (exists == false) {
        // OTP verification logic
        const response = await fetch(`${process.env.REACT_APP_PROXY}/sendOtp`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ email }),
        });
        const data = await response.json();
        console.log('send otp ', data);
        if (data.success) {
          setLoading(false);
          setIsOtpSent(true);
          setIsOtpInputVisible(true); // Show OTP input field
          toast.success('OTP sent successfully');
        } else {
          setLoading(false);
          toast.error('Failed to send OTP. Please try again');
        }
      }
    } catch (error) {
      setLoading(false);
      console.error('Error sending OTP:', error);
      toast.error('Internal error occurred');
    }
  };

  // ==== ** Verify  OTP ** ===
  const verifyOtp = async () => {
    if (!validateOtp()) {
      toast.error('OTP must be a 6-digit number.');
      return;
    }

    try {
      setLoading(true);
      // Replace with your API call to verify OTP
      const response = await fetch(`${process.env.REACT_APP_PROXY}/verifyOtp`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, otp }),
      });
      const data = await response.json();
      if (data.success) {
        toast.success('OTP verified successfully');
        setLoading(false);
        setIsOtpVerified(true);
      } else {
        setLoading(false);
        toast.error('Invalid OTP. Please try again.');
      }
    } catch (error) {
      setLoading(false);
      console.error('Error verifying OTP:', error);
      toast.error('Internal error occurred');
    }
  };

  //user Register
  // const onRegister = () => {
  //   var checkDoamin = email.split('@');
  //   // if (!checkDoamin.includes('sammantrana.com')) {
  //   //   setError('Please enter correct domain');
  //   //   setEValid(true);
  //   //   return;
  //   // }
  //   //validation
  //   if (!email && !password && !confirmPass && !role) {
  //     setError('Please enter details');
  //     setEValid(true);
  //     setPValid(true);
  //     setRoleValid(true);
  //     setPCValid(true);
  //     return;
  //   }
  //   if (!email && !password && !confirmPass) {
  //     setError('Please enter details');
  //     setEValid(true);
  //     setPValid(true);
  //     setPCValid(true);
  //     return;
  //   }
  //   if (role.toLowerCase() === 'student' && !selectUni && !accesscode) {
  //     setError('Please enter a organization & access code.');
  //     setOrgValid(true);
  //     setAcessValid(true);
  //     return;
  //   }
  //   if (!email) {
  //     setError('Please enter an email address.');
  //     setEValid(true);
  //     return;
  //   }
  //   if (!password) {
  //     setError('Please enter a password.');
  //     setPValid(true);
  //     return;
  //   }
  //   if (password.length < 4) {
  //     setError('password (min length 5)');
  //     setPValid(true);
  //     return;
  //   } else if (!/[!@#$%^&*]/.test(password)) {
  //     setError('The password must include special characters.');
  //     setPValid(true);
  //     return;
  //   } else if (!/[0-9]/.test(password)) {
  //     setError('The password must include a number.');
  //     setPValid(true);
  //     return;
  //   } else if (!/[A-Z]/.test(password)) {
  //     setError('The password must include a capital letter.');
  //     setPValid(true);
  //     return;
  //   }
  //   if (!confirmPass) {
  //     setError('Please enter confirm password.');
  //     setPCValid(true);
  //     return;
  //   }
  //   if (password !== confirmPass) {
  //     setError('Entered Passwords do not matched.');
  //     return;
  //   }
  //   if (role.toLowerCase() === 'student') {
  //     if (!selectUni) {
  //       setError('Please enter a organization.');
  //       setOrgValid(true);
  //       return;
  //     }
  //     if (!accesscode) {
  //       setError('Please enter a access_code.');
  //       setAcessValid(true);
  //       return;
  //     }
  //     // else if(!selectUni && !accesscode){
  //     //   setError('Please enter a organization & access code.');
  //     //   setOrgValid(true)
  //     //   setAcessValid(true)
  //     //   return;
  //     // }
  //   }
  //   if (!role) {
  //     setError('Please enter a role.');
  //     setRoleValid(true);
  //     return;
  //   }
  //   if (!['organization', 'student', 'sponsor'].includes(role.toLowerCase())) {
  //     setError('organization/student/sponsor role are only allowed');
  //     setRoleValid(true);
  //     return;
  //   }

  //   setLoading(true);
  //   var domainExist = false;
  //   const getRequestOptions = {
  //     method: 'GET',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       role: role,
  //       email: email,
  //       accesscode: accesscode,
  //       university: selectUni,
  //     },
  //   };

  //   //check university domain for student
  //   fetch(
  //     process.env.REACT_APP_PROXY + '/common/domaincheck',
  //     getRequestOptions
  //   )
  //     .then(res => res.json())
  //     .then(data => {
  //       if (data.success === true) {
  //         domainExist = data.codeExist;
  //         if (data.codeExist === false) {
  //           setLoading(false);
  //           toast.error('please enter a valid access code');
  //           setError('please enter a valid access code');
  //         } else {
  //           var isPass = true;
  //           if (role.toLowerCase() === 'student' && domainExist === true) {
  //             isPass = true;
  //           }

  //           if (role.toLowerCase() === 'student' && domainExist === false) {
  //             isPass = false;
  //           }

  //           if (isPass === true) {
  //             setError('');
  //             // create user in firebase
  //             firebase
  //               .auth()
  //               .createUserWithEmailAndPassword(email, password)
  //               .then(user => {
  //                 const idToken = user.user._lat;
  //                 const requestOptions = {
  //                   method: 'POST',
  //                   headers: {
  //                     'Content-Type': 'application/json',
  //                     auth: idToken,
  //                   },
  //                   body: JSON.stringify({
  //                     role: role,
  //                     accesscode: accesscode,
  //                     university: selectUni,
  //                   }),
  //                 };

  //                 //fetch registered user role
  //                 fetch(
  //                   process.env.REACT_APP_PROXY + '/user/auth',
  //                   requestOptions
  //                 )
  //                   .then(res => res.json())
  //                   .then(data => {
  //                     if (data.success === true) {
  //                       fetch(
  //                         process.env.REACT_APP_PROXY + '/setCustomClaims',
  //                         {
  //                           method: 'POST',
  //                           headers: {
  //                             'Content-Type': 'application/json',
  //                             auth: idToken
  //                           },
  //                           body: JSON.stringify({ uid: user.uid, role })
  //                         })
  //                         .then(data=>{
  //                           if(data.success == true){
  //                             if (
  //                               data.role === 'student' ||
  //                               data.role === 'sponsor'
  //                             ) {
  //                               props.setPage('newprofile');
  //                             }
  //                           }
  //                         }).catch((err)=>{
  //                           console.log("Error in setting role in claims ");
  //                         })

  //                     }
  //                   });

  //                 // console.log("---", token);
  //                 toast.success('Registration success', { autoClose: 2000 });
  //                 setLoading(false);
  //               })
  //               .catch(function (error) {
  //                 // Handle Errors here.
  //                 setLoading(false);
  //                 const errorCode = error.code;
  //                 const errorMessage = error.message;

  //                 if (errorCode === 'auth/weak-password') {
  //                   setError('The password is too weak.');
  //                 } else {
  //                   toast.error(errorMessage, { autoClose: 2000 });
  //                   setError(errorMessage);
  //                 }
  //                 // [END_EXCLUDE]
  //               });
  //           }
  //         }
  //       } else {
  //         setError('internal error');
  //       }
  //     });

  //   // [END createwithemail]
  // };

  const onRegister = async () => {
    try {
      if (!email || !password || !confirmPass || !role) {
        setError('Please enter all details');
        setEValid(!email);
        setPValid(!password);
        setPCValid(!confirmPass);
        setRoleValid(!role);
        return;
      }

      if (role.toLowerCase() === 'student' && (!selectUni || !accesscode)) {
        setError('Please enter an organization & access code.');
        setOrgValid(!selectUni);
        setAcessValid(!accesscode);
        return;
      }

      if (!validateEmail(email)) {
        setError('Please enter a valid email address.');
        setEValid(true);
        return;
      }

      if (!validatePassword(password)) {
        return;
      }

      if (password !== confirmPass) {
        setError('Entered Passwords do not match.');
        setPCValid(true);
        return;
      }

      if (
        !['organization', 'student', 'sponsor'].includes(role.toLowerCase())
      ) {
        setError('Only organization/student/sponsor roles are allowed');
        setRoleValid(true);
        return;
      }

      setLoading(true);

      if (role.toLowerCase() === 'student' && !(await checkDomain())) {
        setError('Please enter a valid access code');
        setLoading(false);
        return;
      }

      await registerUser();
    } catch (error) {
      setLoading(false);
      console.error('Registration error: ', error);
      toast.error(error.message);
    }
  };

  const validateEmail = email => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePassword = password => {
    const errors = [];
    if (password.length < 5)
      errors.push('Password must be at least 5 characters long.');
    if (!/[!@#$%^&*]/.test(password))
      errors.push('The password must include special characters.');
    if (!/[0-9]/.test(password))
      errors.push('The password must include a number.');
    if (!/[A-Z]/.test(password))
      errors.push('The password must include a capital letter.');

    if (errors.length) {
      setError(errors.join(' '));
      setPValid(true);
      return false;
    }
    return true;
  };

  const checkDomain = async () => {
    const getRequestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        role: role,
        email: email,
        accesscode: accesscode,
        university: selectUni,
      },
    };

    const response = await fetch(
      `${process.env.REACT_APP_PROXY}/common/domaincheck`,
      getRequestOptions
    );
    const data = await response.json();
    return data.success && data.codeExist;
  };

  const registerUser = async () => {
    const userCredential = await firebase
      .auth()
      .createUserWithEmailAndPassword(email, password);
    const idToken = await userCredential.user.getIdToken();

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        auth: idToken,
      },
      body: JSON.stringify({
        role: role,
        accesscode: accesscode,
        university: selectUni,
      }),
    };

    // Set data
    const requestOptionsForSetData = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: email,
        role: role,
      }),
    };

    // Setting email in DB, so that we can check user is already exist in db at the time of email verification
    const setDataRes = await fetch(
      `${process.env.REACT_APP_PROXY}/setdata`,
      requestOptionsForSetData
    );
    const GetRes = await setDataRes.json();
    console.log('set data : ', GetRes);

    const authResponse = await fetch(
      `${process.env.REACT_APP_PROXY}/user/auth`,
      requestOptions
    );
    const authData = await authResponse.json();
    console.log('auth ', authData);

    if (authData.success && GetRes.success) {
      await fetch(`${process.env.REACT_APP_PROXY}/setCustomClaims`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          auth: idToken,
        },
        body: JSON.stringify({ uid: userCredential.user.uid, role }),
      });

      console.log('My role ', role);

      if (['student', 'sponsor'].includes(authData.role)) {
        props.setPage('newprofile');
      }

      toast.success('Registration success', { autoClose: 2000 });
    } else {
      toast.error('Failed to set user role');
      return;
    }

    setLoading(false);
  };

  const handleRegisterError = error => {
    setLoading(false);
    const errorCode = error.code;
    const errorMessage = error.message;

    if (errorCode === 'auth/weak-password') {
      setError('The password is too weak.');
    } else {
      setError(errorMessage);
    }

    toast.error(errorMessage, { autoClose: 2000 });
  };

  const getButtonLabel = () => {
    if (currentPage === 'login') {
      return 'Sign In';
    } else if (currentPage === 'register') {
      if (!isOtpSent) {
        return 'Send OTP';
      } else if (isOtpSent && !isOtpVerified) {
        return 'Verify OTP';
      } else if (isOtpSent && isOtpVerified) {
        return 'Create Account';
      }
    }
  };

  const getButtonClickHandler = () => {
    if (currentPage === 'login') {
      return LoginOrRegister;
    } else if (currentPage === 'register') {
      if (!isOtpSent) {
        return sendOtp;
      } else if (isOtpSent && !isOtpVerified) {
        return verifyOtp;
      } else if (isOtpSent && isOtpVerified) {
        return LoginOrRegister;
      }
    }
  };

  return (
    <>
      <>
        {loading && <Spinner />}
        <section className="bannner-image" id="team">
          <Container className={classes.container} maxWidth="lg">
            <Grid item xs={12} md={12}>
              <Card className={classes.card}>
                <Grid container component="main" className={classes.root}>
                  <div
                    className={`col-lg-6 position-relative home-block-3-left pb-lg-0 pb-5 mx-4 d-none d-lg-block`}
                  >
                    <div className="position-relative">
                      <img
                        src={home1}
                        alt=""
                        className="img-fluid radius-image"
                        width="1200px"
                      />
                    </div>
                    <div className="imginfo__box mb-5 py-4">
                      <h6 className="imginfo__title">Join Us Today!</h6>
                    </div>
                  </div>
                  <Grid item xs={12} sm={12} md={5} elevation={6}>
                    <div className={classes.paper}>
                      <h3 className="title-style">
                        {currentPage === 'register'
                          ? 'Join us to make a difference'
                          : 'Join us to make a difference'}
                      </h3>
                      <TextField
                        className={classes.customTextField}
                        variant="outlined"
                        margin="normal"
                        required
                        error={Emailvalid}
                        fullWidth
                        id="email"
                        placeholder="Email Address"
                        name="email"
                        value={email}
                        autoComplete="off"
                        autoFocus
                        onChange={e => {
                          e.preventDefault();
                          e.persist();
                          setEmail(e.target.value);
                        }}
                        disabled={isOtpSent || isOtpVerified}
                      />

                      {currentPage === 'login' && (
                        <>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          error={Passvalid}
                          value={password}
                          name="password"
                          placeholder="Password"
                          type={passwordShown ? 'text' : 'password'}
                          id="password"
                          autoComplete="off"
                          onChange={e => {
                            e.preventDefault();
                            e.persist();
                            setPassword(e.target.value);
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={togglePasswordVisiblity}
                                >
                                  {passwordShown ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      <div className="forgot-password-container">
                      <Link
                        style={{
                          textDecoration: 'underline',
                          whiteSpace: 'nowrap',
                          color: '#232323',
                          cursor: 'pointer',
                          display: 'inline-block',
                          fontSize: '16px',
                          marginTop: '10px',
                          fontWeight: 'bold',
                        }}
                        size="large"
                        onClick={() => setOpen(true)}
                      >
                        {currentPage === 'login' ? 'Forgot password ? ' : ''}
                      </Link>
                      </div>
                      </>
                      )}

                      {isOtpSent && !isOtpVerified && (
                        <TextField
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          id="otp"
                          placeholder="Enter OTP"
                          name="otp"
                          value={otp}
                          error={Boolean(otpError)}
                          onChange={e => {
                            e.preventDefault();
                            e.persist();
                            setOtp(e.target.value);
                          }}
                        />
                      )}
                      {otpError && (
                        <div style={{ color: 'red' }}>{otpError}</div>
                      )}
                      {isOtpVerified && currentPage === 'register' && (
                        <>
                          <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            error={Passvalid}
                            value={password}
                            name="password"
                            placeholder="Password"
                            type={passwordShown ? 'text' : 'password'}
                            id="password"
                            autoComplete="off"
                            onChange={e => {
                              e.preventDefault();
                              e.persist();
                              setPassword(e.target.value);
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={togglePasswordVisiblity}
                                  >
                                    {passwordShown ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                          <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            error={PassCvalid}
                            fullWidth
                            value={confirmPass}
                            name="confirmpassword"
                            placeholder="Confirm Password"
                            type={passwordShown ? 'text' : 'password'}
                            id="confirmpassword"
                            autoComplete="off"
                            onChange={e => {
                              e.preventDefault();
                              e.persist();
                              setConfirmPass(e.target.value);
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={togglePasswordVisiblity}
                                  >
                                    {passwordShown ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                          <Autocomplete
                            id="combo-box-demo"
                            options={roles}
                            getOptionLabel={option => option.title}
                            fullWidth
                            style={{ marginTop: 18 }}
                            onChange={(e, v, r) => {
                              if (v !== null) {
                                setRole(v.title.toLowerCase());
                                props.setRole(v.title.toLowerCase());
                              }
                            }}
                            renderInput={params => (
                              <TextField
                                {...params}
                                placeholder="Role"
                                error={Rolevalid}
                                variant="outlined"
                              />
                            )}
                          />
                          {role === 'student' && (
                            <>
                              <Autocomplete
                                id="combo-box-demo"
                                options={university}
                                getOptionLabel={option => option}
                                fullWidth
                                style={{ marginTop: 18 }}
                                onChange={(e, v, r) => {
                                  if (v !== null) {
                                    setSelectedUni(v);
                                  }
                                }}
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    placeholder="Select the Reference Organisation"
                                    variant="outlined"
                                    error={Orgvalid}
                                  />
                                )}
                              />
                              <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="accesscode"
                                placeholder="Enter the Access Code from Reference Organisation"
                                name="accesscode"
                                value={accesscode}
                                error={Accessvalid}
                                onChange={e => {
                                  e.preventDefault();
                                  e.persist();
                                  SetAccessCoupan(e.target.value);
                                }}
                              />
                            </>
                          )}
                        </>
                      )}
                  
                      <div
                        style={{ width: '100%', padding: '8px 4px 4px 4px' }}
                      >
                        <label style={{ color: 'red' }}>{error}</label>
                      </div>
                      
                      <Grid
                        container
                        component="main"
                        style={{ justifyContent: 'space-between' }}
                        spacing={4}
                      >
                        <Grid item xs={12} sm={12} md={12} elevation={4}>
                          <Button
                            style={{
                              width: '100%',
                              margin: 0,
                              backgroundColor: '#77000d',
                              color: '#ffffff',
                              padding: '8px',
                              border: '1px solid #232323',
                              borderRadius: 40,
                            }}
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={getButtonClickHandler()}
                          >
                            {getButtonLabel()}
                          </Button>
                        </Grid>

                        <div
                          style={{
                            width: '100%',
                            padding: '8px 4px 4px 4px',
                            display: 'flex',
                            justifyContent: 'space-around',
                          }}
                        >
                          <Link
                            style={{
                              whiteSpace: 'nowrap',
                              color: '#232323',
                              cursor: 'pointer',
                              marginBottom: '30px',
                              display: 'inline-block',
                              fontSize: '16px',
                              marginTop: '10px',
                              fontWeight: 'bold',
                            }}
                            size="large"
                            onClick={toggleLoginRegister}
                          >
                            {currentPage === 'login' ? (
                              <span>
                                Not Registered yet?{' '}
                                <span
                                  style={{
                                    color: 'blue',
                                    fontWeight: 'bold',
                                    cursor: 'pointer',
                                    textDecoration: 'underline',
                                  }}
                                >
                                  Create account
                                </span>
                              </span>
                            ) : (
                              <span>
                                Already have an account?{' '}
                                <span
                                  style={{
                                    color: 'blue',
                                    fontWeight: 'bold',
                                    cursor: 'pointer',
                                    textDecoration: 'underline',
                                  }}
                                >
                                  Log in
                                </span>
                              </span>
                            )}
                          </Link>
                        </div>
                        {/* <Grid item xs={6} sm={6} md={4} elevation={4}>
                          <Button
                            style={{
                              margin: 0,
                              width: '140px',
                              backgroundColor: '#ffffff',
                              color: '#232323',
                              border: '1px solid #232323',
                              padding: '8px',
                              borderRadius: 40,
                            }}
                            color="primary"
                            fullWidth
                            variant="contained"
                            onClick={toggleLoginRegister}
                          >
                            {currentPage === 'login' ? 'Sign Up' : 'Login'}
                          </Button>
                        </Grid> */}
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              </Card>
              {currentPage === 'login' && (
                <Dialog
                  open={open}
                  onClose={() => setOpen(false)}
                  aria-labelledby="form-dialog-title"
                  PaperProps={{
                    style: {
                      padding: '8px',
                      borderRadius: '20px',
                      backgroundColor: '#ffffff',
                      boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1)',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    },
                  }}
                >
                  <DialogTitle
                    id="form-dialog-title"
                    style={{ textAlign: 'center', fontWeight: 'bold' }}
                  >
                    Forgot your password?
                  </DialogTitle>
                  <DialogContent style={{ textAlign: 'center', width: '100%' }}>
                    <p style={{ marginBottom: '20px' }}>
                      We'll email you a link to reset your password.
                    </p>
                    <input
                      type="email"
                      placeholder="Email"
                      value={modalEmail}
                      onChange={e => setModalEmail(e.target.value)}
                      style={{
                        width: '100%',
                        padding: '15px',
                        borderRadius: '25px',
                        border: '1px solid #ccc',
                        marginBottom: '10px',
                        boxSizing: 'border-box',
                      }}
                    />
                  </DialogContent>
                  <DialogActions
                    style={{
                      flexDirection: 'column',
                      alignItems: 'center',
                      width: '100%',
                      paddingBottom: '20px',
                    }}
                  >
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: '#77000D',
                        color: '#ffffff',
                        borderRadius: '50px',
                        width: '90%',
                        height: '40px',
                        marginBottom: '10px',
                      }}
                      onClick={async () => {
                        if (!validateEmail(modalEmail) || !modalEmail) {
                          toast.error('Please enter a valid email address.');
                          return;
                        }
                        setLoading(true);
                        const auth = firebase.auth();
                        const user = await checkUserExists(modalEmail);
                        if (user) {
                          auth
                            .sendPasswordResetEmail(modalEmail)
                            .then(function () {
                              setOpen(false);
                              toast.success('Reset link sent successfully');
                              setLoading(false);
                            })
                            .catch(function (error) {
                              toast.error(
                                'Password reset function has failed.'
                              );
                              setLoading(false);
                            });
                        } else {
                          toast.error("User doesn't exist");
                          setLoading(false);
                        }
                      }}
                    >
                      Send me a password reset link
                    </Button>
                    <Button
                      variant="contained"
                      style={{
                        border: '1px solid black',
                        background: 'white',
                        color: '#000000',
                        borderRadius: '50px',
                        width: '90%',
                        height: '40px',
                        marginLeft: '-4px',
                      }}
                      onClick={() => setOpen(false)}
                    >
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>
              )}
              <Dialog
                open={open2}
                onClose={() => setOpen2(false)}
                aria-labelledby="form-dialog-title"
              >
                <DialogTitle id="form-dialog-title" />
                <DialogContent>
                  <Autocomplete
                    id="combo-box-demo"
                    options={roles}
                    getOptionLabel={option => option.title}
                    fullWidth
                    style={{ width: 150 }}
                    onChange={(e, v, r) => {
                      if (v !== null) {
                        setRole(v.title.toLowerCase());
                        props.setRole(v.title.toLowerCase());
                      }
                    }}
                    renderInput={params => (
                      <TextField {...params} label="Role" variant="outlined" />
                    )}
                  />
                </DialogContent>
                <DialogActions>
                  <Button color="primary" onClick={LoginOrRegister}>
                    Sign Up
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
            <br />
            <br />
          </Container>
        </section>
      </>
    </>
  );
}
