// Functionality of this code : This code has the dashboard on admin pannel
// Input : None
// Output : Dashboard admin page
// Written by: CIS

// import Menu from "./Sidebar";
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles, withStyles } from '@material-ui/core/styles';

export default function DashBoard() {
  const [error, setError] = React.useState('');
  const [student, setStudent] = React.useState([]);
  const [sponsor, setSponsor] = React.useState([]);
  const [codes, setCodes] = React.useState([]);
  const [requests, setRequests] = React.useState([]);
  const [organization, setOrg] = React.useState([]);
  const [provider, setProvider] = React.useState([]);
  const [voucher, setVoucher] = React.useState([]);
  const [ReqDetails, setDetail] = React.useState([]);
  const [assignedVoucher, setAssignedVoucher] = React.useState([]);
  const useStyles = makeStyles(() => ({
    main: {
      marginTop: '3%',
      '@media (max-width: 767px)': {
        marginTop: '20%',
      },
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    fetch(process.env.REACT_APP_PROXY + '/admin/student')
      .then(res => res.json())
      .then(data => {
        let resp = data.data;
        if (data.success === false) {
          setError(data.message);
        } else {
          const unique = [...new Set(resp)];
          setStudent(unique);
        }
      });

    fetch(process.env.REACT_APP_PROXY + '/admin/uniqueGift')
      .then(res => res.json())
      .then(data => {
        let resp = data.data;
        if (data.success === false) {
          setError(data.message);
        } else {
          const unique = [...new Set(resp)];
          setVoucher(unique);
        }
      });

    fetch(process.env.REACT_APP_PROXY + '/admin/sponsor')
      .then(res => res.json())
      .then(data => {
        console.log(data, '------->>>>>');
        let resp = data.data;
        // let uniqueObjArray = [
        //   ...new Map(data.data.map(item => [item['uid'], item])).values(),
        // ];
        if (data.success === false) {
          setError(data.message);
        } else {
          const unique = [
            ...new Map(data.data.map(item => [item['uid'], item])).values(),
          ];
          setSponsor(unique);
          // setSponsor(uniqueObjArray);
        }
      });

    fetch(process.env.REACT_APP_PROXY + '/admin/assignedVoucher')
      .then(res => res.json())
      .then(data => {
        let resp = data.data;
        if (data.success === false) {
          setError(data.message);
        } else {
          const unique = [...new Set(resp)];
          setAssignedVoucher(unique);
        }
      });

    // fetch(process.env.REACT_APP_PROXY + '/admin/totalrequests')
    //   .then(res => res.json())
    //   .then(data => {
    //     let resp = data.data;
    //     if (data.success === false) {
    //       setError(data.message);
    //     } else {
    //       const unique = [...new Set(resp)];
    //       setRequests(unique);
    //     }
    //   });

    fetch(process.env.REACT_APP_PROXY + '/admin/fullrequests')
      .then(res => res.json())
      .then(data => {
        let resp = data.data;
        if (data.success === false) {
          setError(data.message);
        } else {
          const unique = [...new Set(resp)];
          setRequests(unique);
        }
      });

    fetch(process.env.REACT_APP_PROXY + '/admin/organization')
      .then(res => res.json())
      .then(data => {
        let resp = data.data;
        if (data.success === false) {
          setError(data.message);
        } else {
          const unique = [...new Set(resp)];
          setOrg(unique);
        }
      });

    fetch(process.env.REACT_APP_PROXY + '/admin/providers')
      .then(res => res.json())
      .then(data => {
        let resp = data.data;
        if (data.success === false) {
          setError(data.message);
        } else {
          const unique = [...new Set(resp)];
          setProvider(unique);
        }
      });

    fetch(process.env.REACT_APP_PROXY + '/admin/getDetails')
      .then(res => res.json())
      .then(data => {
        let resp = data.data;
        if (data.success === false) {
          setError(data.message);
        } else {
          const unique = [...new Set(resp)];
          setDetail(unique);
        }
      });

    fetch(process.env.REACT_APP_PROXY + '/admin/accesscodes')
      .then(res => res.json())
      .then(data => {
        let resp = data.data;
        if (data.success === false) {
          setError(data.message);
        } else {
          const unique = [...new Set(resp)];
          setCodes(unique);
        }
      });
  }, []);

  return (
    <div className={classes.main}>
      <div class="content-wrapper">
        {/* Content Header (Page header) */}
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6" style={{ marginTop: '4%' }}>
                <h1 className="m-0" style={{ color: '#F4113D' }}>
                  Dashboard
                </h1>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-3 col-6">
                <div className="small-box shadow-lg p-3 mb-5 bg-white rounded">
                  <div className="inner">
                    <h3 style={{ color: '#2998D1' }}>{student.length}</h3>
                    <p style={{ color: '#2998D1' }}>Total Students</p>
                  </div>
                  <div className="icon">
                    <i className="fas fa-user-graduate" />
                  </div>
                  <Link
                    to="/StudentTable"
                    className="small-box-footer btn btn-outline-primary"
                  >
                    More info <i className="fas fa-arrow-circle-right" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="small-box shadow-lg p-3 mb-5 bg-white rounded">
                  <div className="inner">
                    <h3 style={{ color: '#2998D1' }}>{sponsor.length}</h3>
                    <p style={{ color: '#2998D1' }}>Total Sponsors</p>
                  </div>
                  <div className="icon">
                    <i className="fas fa-people-carry" />
                  </div>
                  <Link
                    to="/SponsorTable"
                    className="small-box-footer btn btn-outline-primary"
                  >
                    More info <i className="fas fa-arrow-circle-right" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="small-box shadow-lg p-3 mb-5 bg-white rounded">
                  <div className="inner">
                    <h3 style={{ color: '#2998D1' }}>{codes.length}</h3>
                    <p style={{ color: '#2998D1' }}>Total Access Codes</p>
                  </div>
                  <div className="icon">
                    <i className="fas fa-layer-group" />
                  </div>
                  <Link
                    to="/AccessCodes"
                    className="small-box-footer btn btn-outline-primary"
                  >
                    More info <i className="fas fa-arrow-circle-right" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="small-box shadow-lg p-3 mb-5 bg-white rounded">
                  <div className="inner">
                    <h3 style={{ color: '#2998D1' }}>{requests.length}</h3>
                    <p style={{ color: '#2998D1' }}>Fullfilled Requests</p>
                  </div>
                  <div className="icon">
                    <i className="fas fa-hourglass-start" />
                  </div>
                  <Link
                    to="/FullfilledRequests"
                    className="small-box-footer btn btn-outline-primary"
                  >
                    More info <i className="fas fa-arrow-circle-right" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="small-box shadow-lg p-3 mb-5 bg-white rounded">
                  <div className="inner">
                    <h3 style={{ color: '#2998D1' }}>{organization.length}</h3>
                    <p style={{ color: '#2998D1' }}>Total Organizations</p>
                  </div>
                  <div className="icon">
                    <i className="far fa-building" />
                  </div>
                  <Link
                    to="/Organizations"
                    className="small-box-footer btn btn-outline-primary"
                  >
                    More info <i className="fas fa-arrow-circle-right" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="small-box shadow-lg p-3 mb-5 bg-white rounded">
                  <div className="inner">
                    <h3 style={{ color: '#2998D1' }}>{provider.length}</h3>
                    <p style={{ color: '#2998D1' }}>Total Providers</p>
                  </div>
                  <div className="icon">
                    <i className="fas fa-handshake" />
                  </div>
                  <Link
                    to="/Providers"
                    className="small-box-footer btn btn-outline-primary"
                  >
                    More info <i className="fas fa-arrow-circle-right" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="small-box shadow-lg p-3 mb-5 bg-white rounded">
                  <div className="inner">
                    <h3 style={{ color: '#2998D1' }}>{voucher.length}</h3>
                    <p style={{ color: '#2998D1' }}>Total Uniquegifts</p>
                  </div>
                  <div className="icon">
                    <i className="fas fa-layer-group" />
                  </div>
                  <Link
                    to="/Uniquegifts"
                    className="small-box-footer btn btn-outline-primary"
                  >
                    More info <i className="fas fa-arrow-circle-right" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="small-box shadow-lg p-3 mb-5 bg-white rounded">
                  <div className="inner">
                    <h3 style={{ color: '#2998D1' }}>{ReqDetails.length}</h3>
                    <p style={{ color: '#2998D1' }}>Assigned Request</p>
                  </div>
                  <div className="icon">
                    <i className="fas fa-handshake" />
                  </div>
                  <Link
                    to="/ReqDetails"
                    className="small-box-footer btn btn-outline-primary"
                  >
                    More info <i className="fas fa-arrow-circle-right" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="small-box shadow-lg p-3 mb-5 bg-white rounded">
                  <div className="inner">
                    <h3 style={{ color: '#2998D1' }}>
                      {assignedVoucher.length}
                    </h3>
                    <p style={{ color: '#2998D1' }}>Purchased Vouchers</p>
                  </div>
                  <div className="icon">
                    <i className="fas fa-handshake" />
                  </div>
                  <Link
                    to="/AssignedVoucher"
                    className="small-box-footer btn btn-outline-primary"
                  >
                    More info <i className="fas fa-arrow-circle-right" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="row">
              <section className="col-lg-7 connectedSortable"></section>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
