import React, { useState } from 'react';
import './css/modal.css';
import { TextField } from '@material-ui/core';
import { ErrorMessage, Form, Formik } from 'formik';
import * as Yup from 'yup';
import Dialog from '@material-ui/core/Dialog';
import axios from 'axios';
import { toast } from 'react-toastify';
import Spinner from './utils/spinner';

// Contact Us popup
export default function ContactPage(props) {
  const handleClose = () => {
    props.setContact(false);
  };

  const [loading, setLoading] = useState(false);

  // Validation schema
  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, 'Your name needs to be more than 2 characters')
      .matches(/^([^0-9]*)$/, 'Only alphabets are allowed')
      .required('Required'),
    lastName: Yup.string()
      .min(2, 'Your name needs to be more than 2 characters')
      .matches(/^([^0-9]*)$/, 'Only alphabets are allowed')
      .required('Required'),
    email: Yup.string()
      .email('Please enter a valid email')
      .required('Required'),
    phoneNumber: Yup.string()
      .matches(/^[0-9]+$/, 'Please enter a valid phone number')
      .min(10, 'Phone number should be at least 10 digits')
      .max(12, 'Phone number should be at most 12 digits')
      .required('Required'),
    message: Yup.string().required('Required'),
  });

  // Function to send mail
  const handleSendMessage = async (values, { resetForm }) => {
    setLoading(true);
    try {
      const OwnerSubject = 'Message from Website Contact Form';
      const ownerHtml = `
        Some user inquiry
        ${values.firstName} ${values.lastName} had an inquiry to your website
        ${values.message}
      `;

      const subject = 'Thank You for Reaching Out';
      const userHtml = `
        Thank you for your inquiry. We will get back to you soon.
      `;

      const usermail = values.email;

      const response = await axios.post(
        process.env.REACT_APP_PROXY + '/send-email',
        {
          usermail,
          subject,
          OwnerSubject,
          userHtml,
          ownerHtml,
        }
      );

      console.log(response.data);
      setLoading(false);
      resetForm();
      props.setContact(false);
      toast.success('Message sent successfully', { autoClose: 2000 });
    } catch (error) {
      setLoading(false);
      console.error('Error sending message:', error);
      toast.error('Failed to send message', { autoClose: 2000 });
    }
  };

  return (
    <>
      {loading && <Spinner />}
      <Dialog
        fullWidth
        modal="false"
        open={props.open}
        onClose={handleClose}
        PaperProps={{
          style: {
            background: 'none',
            boxShadow: 'none',
            maxWidth: 800,
            overflow: 'auto',
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
            '&::WebkitScrollbar': {
              display: 'none',
            },
          },
        }}
      >
        <div className="admin-modal">
          <div className="top-admin">
            <h2>Contact Us</h2>
          </div>
          <div className="form-bottom">
            <Formik
              initialValues={{
                firstName: '',
                lastName: '',
                email: '',
                phoneNumber: '',
                message: '',
              }}
              validationSchema={validationSchema}
              onSubmit={handleSendMessage}
            >
              {({
                values,
                handleChange,
                handleSubmit,
                isValid,
                touched,
                errors,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="firstName">First Name</label>
                    <TextField
                      type="text"
                      id="firstName"
                      name="firstName"
                      placeholder="Enter First Name"
                      value={values.firstName}
                      onChange={handleChange}
                      helperText={
                        touched.firstName && errors.firstName
                          ? errors.firstName
                          : ''
                      }
                      error={touched.firstName && Boolean(errors.firstName)}
                      fullWidth
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="lastName">Last Name</label>
                    <TextField
                      type="text"
                      id="lastName"
                      name="lastName"
                      placeholder="Enter your Last Name"
                      value={values.lastName}
                      onChange={handleChange}
                      helperText={
                        touched.lastName && errors.lastName
                          ? errors.lastName
                          : ''
                      }
                      error={touched.lastName && Boolean(errors.lastName)}
                      fullWidth
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email Address</label>
                    <TextField
                      type="text"
                      id="email"
                      name="email"
                      placeholder="Enter your Email Address"
                      value={values.email}
                      onChange={handleChange}
                      helperText={
                        touched.email && errors.email ? errors.email : ''
                      }
                      error={touched.email && Boolean(errors.email)}
                      fullWidth
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="phoneNumber">Phone Number</label>
                    <TextField
                      type="text"
                      id="phoneNumber"
                      name="phoneNumber"
                      placeholder="Enter your phone number"
                      value={values.phoneNumber}
                      onChange={handleChange}
                      helperText={
                        touched.phoneNumber && errors.phoneNumber
                          ? errors.phoneNumber
                          : ''
                      }
                      error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                      fullWidth
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="message">Message</label>
                    <TextField
                      id="message"
                      name="message"
                      placeholder="Type your message here..."
                      multiline
                      minRows={3}
                      value={values.message}
                      onChange={handleChange}
                      helperText={
                        touched.message && errors.message ? errors.message : ''
                      }
                      error={touched.message && Boolean(errors.message)}
                      fullWidth
                      required
                    />
                  </div>
                  <div className="form-group btn-listing">
                    <button className="btn btn-border" onClick={handleClose}>
                      Cancel
                    </button>
                    <button className="btn btn-fill" type="submit">
                      Send Message
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Dialog>
    </>
  );
}
