import Tooltip from '@mui/material/Tooltip';
import { tooltipMessages } from './tooltipMessages';

// Utility function to create a Tooltip
export const createTooltip = (messageKey, position = 'bottom', children) => {
  const message = tooltipMessages[messageKey];
  return (
    <Tooltip
      title={message}
      placement={position} // 'bottom', 'top', 'left', 'right'
      arrow //an arrow to the tooltip
    >
      {children}
    </Tooltip>
  );
};
